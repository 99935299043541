import React from "react";
import { Box, Grid } from "@mui/material";
import SpacingContent from "./SpacingContent";
import BannerSwiper from "./swipers/BannerSwiper";
import { SwiperSlide } from "swiper/react";
import banner1 from "../assets/media/images/banner/banner1.jpg";
import banner2 from "../assets/media/images/banner/banner2.jpg";
import banner3 from "../assets/media/images/banner/banner3.jpg";
import banner4 from "../assets/media/images/banner/banner4.jpg";
import banner5 from "../assets/media/images/banner/banner5.jpg";
import banner6 from "../assets/media/images/banner/banner6.jpg";

const Banner = () => {
    // ############ Redux state ##############
    const bannerImages = [
        {
            id: 1,
            image: banner1,
        },
        {
            id: 2,
            image: banner2,
        },
        {
            id: 3,
            image: banner3,
        },
        {
            id: 4,
            image: banner4,
        },
        {
            id: 5,
            image: banner5,
        },
        {
            id: 6,
            image: banner6,
        },
    ];

    return (
        <>
            <BannerSwiper
                content={"banner"}
                conHeight={{
                    md: "100vh",
                    sm: "100vh",
                    xs: "80vh",
                }}
            >
                {bannerImages.map((slide) => (
                    <SwiperSlide key={slide.id}>
                        <Box
                            sx={{
                                height: {
                                    md: "100vh",
                                    sm: "100vh",
                                    xs: "80vh",
                                },
                                width: "100vw",
                                display: "flex",
                                alignItems: "center",
                                backgroundImage: `url(${slide.image})`,
                                backgroundPosition: {
                                    md: "center center",
                                    sm: "center center",
                                    xs: "center right",
                                },
                                backgroundSize: {
                                    md: "100% 100%",
                                    xs: "700px 80vh",
                                },
                                backgroundRepeat: "no-repeat",
                            }}
                        >
                            <Box
                                sx={{
                                    height: "100vh",
                                    width: "100vw",
                                    display: "flex",
                                    alignItems: "center",
                                    background: `rgb(21, 110, 72, .1)`,
                                }}
                            >
                                <SpacingContent>
                                    <Grid
                                        container
                                        spacing={2}
                                        padding={2}
                                        sx={{ mt: 5 }}
                                        justifyContent={"center"}
                                    >
                                        {/* <Grid item sm={10} xs={12}>
                                                <Typography
                                                    className="mh-font"
                                                    sx={{
                                                        fontSize: {
                                                            md: 80,
                                                            xs: 40,
                                                        },
                                                        fontWeight: 'bold',
                                                        color: colors.white,
                                                        lineHeight: 1,
                                                    }}
                                                >
                                                    {slide.title1}
                                                </Typography>
                                                <Typography
                                                    className="mh-font"
                                                    sx={{
                                                        fontSize: {
                                                            md: 80,
                                                            xs: 40,
                                                        },
                                                        fontWeight: 'bold',
                                                        color: colors.white,
                                                        lineHeight: 1,
                                                        width: {
                                                            md: '60%',
                                                            xs: '100%',
                                                        },
                                                    }}
                                                >
                                                    {slide.title2}
                                                </Typography>
                                            </Grid>
                                            <Grid item sm={10} xs={12}>
                                                <Link to={'/about-us'}>
                                                    <CustomButton1
                                                        color="secondary"
                                                        title={
                                                            language ===
                                                            'swahili'
                                                                ? 'Jionee zaidi'
                                                                : 'Learn more'
                                                        }
                                                        sx={{
                                                            color:
                                                                colors.primary,
                                                            fontWeight: 'bold',
                                                            px: 10,
                                                        }}
                                                    />
                                                </Link>
                                            </Grid> */}
                                    </Grid>
                                </SpacingContent>
                            </Box>
                        </Box>
                    </SwiperSlide>
                ))}
            </BannerSwiper>
        </>
    );
};

export default Banner;
