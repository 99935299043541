import { createSlice } from "@reduxjs/toolkit";
import {
    homeProductsData,
    productsData,
} from "../../dummyData/productsDummyData";

// => initialState
const initialState = {
    products: productsData,
    homeProducts: homeProductsData,
};
export const productSlice = createSlice({
    name: "productSlice",
    initialState,
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload;
        },
        setHomeProducts: (state, action) => {
            state.homeProducts = action.payload;
        },
    },
});

export const { setProducts, setHomeProducts } = productSlice.actions;
export default productSlice.reducer;
