import { systemConfigSelector } from '../states/features/selectors';
import { useSelector } from 'react-redux';

const lightColor = {
    primary: '#156E48',
    secondary: '#9bca42',
    tertiary: '#38b000',
    bgColor1: '#b0f0d5',
    bgColor2: '#E0D7FF',
    bgColor3: '#ECE7FF',
    bgWhite: '#ECE7FF',
    bgColor4: '#fff4e3',
    bgColor5: '#fff4e3',
    bgColor6: '#fff4e3',
    footerBg: '#DBDBDB',
    warning: '#FFD900',
    success: '#00EE76',
    error: '#F43249',
    info: '#027BBD',
    white: '#fff',
};

const darkColor = {
    primary: '#156E48',
    secondary: '#9CCB42',
    bgColor1: '#D7CCFB',
    bgColor2: '#E0D7FF',
    bgColor3: '#ECE7FF',
    bgColor4: '#fff4e3',
    bgColor5: '#fff4e3',
    bgColor6: '#fff4e3',
    warning: '#FFD900',
    success: '#00EE76',
    error: '#F43249',
    info: '#027BBD',
};

const useColor = () => {
    // ######### Inner states #############
    let color = null;

    // ######### Redux states #############
    const { darkMode } = useSelector(systemConfigSelector);

    if (darkMode) {
        color = darkColor;
    } else {
        color = lightColor;
    }
    return color;
};

// ######### Exports #############
export const colors = lightColor;
export default useColor;
