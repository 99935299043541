import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../hooks/useColor";

const ProductWidget = ({ product }) => {
    return (
        <>
            <Box
                sx={{
                    boxShadow: `1px 1px 15px 0px ${colors.footerBg}`,
                }}
            >
                <Avatar
                    variant="square"
                    src={product.image}
                    sx={{
                        height: { md: 300, xs: 300 },
                        width: { md: "100%", xs: "90%" },
                        img: {
                            objectFit: "contain",
                        },
                    }}
                />
                <Box
                    sx={{
                        bgcolor: colors.primary,
                        width: "100%",
                        p: 2,
                    }}
                >
                    <Typography
                        sx={{
                            textAlign: "center",
                            textTransform: "uppercase",
                            color: colors.white,
                            fontWeight: "bold",
                            fontSize: 16,
                        }}
                    >
                        {product.title}
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default ProductWidget;
