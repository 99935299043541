import banner1 from "../../assets/media/images/banner/banner1.jpg";
import banner2 from "../../assets/media/images/banner/banner2.jpg";
import banner3 from "../../assets/media/images/banner/banner3.jpg";
import banner4 from "../../assets/media/images/banner/banner4.jpg";
import banner5 from "../../assets/media/images/banner/banner5.jpg";
import banner6 from "../../assets/media/images/banner/banner6.jpg";

export const bannerSlideImages = [
    {
        id: 1,
        image: banner1,
    },
    {
        id: 2,
        image: banner2,
    },
    {
        id: 3,
        image: banner3,
    },
    {
        id: 4,
        image: banner4,
    },
    {
        id: 5,
        image: banner5,
    },
    {
        id: 6,
        image: banner6,
    },
];
