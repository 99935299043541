import { Button } from "@mui/material";
import React from "react";
import { colors } from "../../hooks/useColor";

const CustomButton1 = ({
    variant,
    title,
    color = "primary",
    sx,
    startIcon,
    endIcon,
}) => {
    return (
        <>
            <Button
                color={color}
                startIcon={startIcon}
                endIcon={endIcon}
                variant={variant ? variant : "contained"}
                sx={{
                    borderRadius: "50px !important",
                    borderTopRightRadius: "5px !important",
                    boxShadow: `none !important`,
                    px: 2,
                    border: `1px solid ${colors.secondary}`,
                    ":hover": {
                        border: `1px solid ${colors.secondary}`,
                    },
                    ...sx,
                }}
            >
                {title}
            </Button>
        </>
    );
};

export default CustomButton1;
