import React from 'react';
import { Box, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ErrorMessage, useField } from 'formik';
import { colors } from '../../hooks/useColor';

const CustomInput = styled(TextField)({});

const CustomTextField = ({
    label,
    type,
    value,
    prepend,
    readOnly,
    labelColor,
    ...props
}) => {
    const [field, meta] = useField(props);
    return (
        <>
            <Box
                sx={{
                    marginTop: 2,
                    borderRadius: 1.5,
                    overflow: 'hidden',
                    bgcolor: colors.white,
                }}
            >
                <CustomInput
                    error={meta.touched && meta.error ? true : false}
                    label={label}
                    variant="filled"
                    size="small"
                    fullWidth
                    type={type}
                    sx={{
                        label: {
                            color: `${labelColor} !important`,
                        },
                    }}
                    placeholder={field.label}
                    {...field}
                    {...props}
                    InputProps={{
                        startAdornment: prepend && (
                            <InputAdornment position="start">
                                {prepend}
                            </InputAdornment>
                        ),
                        readOnly: readOnly ? true : false,
                    }}
                />
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
            </Box>
        </>
    );
};

export default CustomTextField;
