import {
    CameraAltOutlined,
    Group,
    ManageAccounts,
    PhoneForwarded,
    Window,
    Work,
} from "@mui/icons-material";

export const navbarLinks = [
    {
        id: 1,
        title: "Home",
        icon: <Window className="list-icon" />,
        url: "/",
    },
    {
        id: 2,
        title: "About us",
        icon: <ManageAccounts className="list-icon" />,
        url: "/about-us",
    },
    {
        id: 3,
        title: "What we do",
        icon: <Work className="list-icon" />,
        url: "/what-we-do",
    },
    {
        id: 4,
        title: "Our products",
        icon: <Work className="list-icon" />,
        url: "#",
        subLinks: [
            {
                id: 1,
                title: "Our history",
                icon: <Work className="list-icon" />,
                url: "/about-us",
            },
            {
                id: 2,
                title: "Mission, Vission and values",
                icon: <Work className="list-icon" />,
                url: "/about-us",
            },
            {
                id: 3,
                title: "Management team",
                icon: <Work className="list-icon" />,
                url: "/about-us",
            },
            {
                id: 4,
                title: "Our history",
                icon: <Work className="list-icon" />,
                url: "/about-us",
            },
            {
                id: 5,
                title: "Mission, Vission and values",
                icon: <Work className="list-icon" />,
                url: "/about-us",
            },
            {
                id: 6,
                title: "Management team",
                icon: <Work className="list-icon" />,
                url: "/about-us",
            },
        ],
    },
    {
        id: 5,
        title: "Gallery",
        icon: <CameraAltOutlined className="list-icon" />,
        url: "/gallaries",
    },
    {
        id: 6,
        title: "Our dealers",
        icon: <Group className="list-icon" />,
        url: "/our-dealers",
    },
    {
        id: 7,
        title: "Contact us",
        icon: <PhoneForwarded className="list-icon" />,
        url: "/contact-us",
    },
];

export const navbarLinks1 = [
    {
        id: 1,
        title: "Home",
        icon: <Window className="list-icon" />,
        url: "/",
    },
    {
        id: 2,
        title: "About us",
        icon: <ManageAccounts className="list-icon" />,
        url: "/about-us",
    },
    {
        id: 3,
        title: "What we do",
        icon: <Work className="list-icon" />,
        url: "/what-we-do",
    },
];

export const productLink = {
    id: 4,
    title: "Our products",
    icon: <Work className="list-icon" />,
    url: "#",
};

export const navbarLinks2 = [
    {
        id: 5,
        title: "Gallery",
        icon: <CameraAltOutlined className="list-icon" />,
        url: "/gallaries",
    },
    {
        id: 6,
        title: "Our dealers",
        icon: <Group className="list-icon" />,
        url: "/our-dealers",
    },
    {
        id: 7,
        title: "Contact us",
        icon: <PhoneForwarded className="list-icon" />,
        url: "/contact-us",
    },
];

export const navbarLinksSwahili = [
    {
        id: 1,
        title: "Nyumbani",
        icon: <Window className="list-icon" />,
        url: "/",
    },
    {
        id: 2,
        title: "Kuhusu",
        icon: <ManageAccounts className="list-icon" />,
        url: "/about-us",
        subLinks: [
            {
                id: 1,
                title: "Our history",
                icon: <Work className="list-icon" />,
                url: "/about-us",
            },
            {
                id: 2,
                title: "Mission, Vission and values",
                icon: <Work className="list-icon" />,
                url: "/about-us",
            },
            {
                id: 3,
                title: "Management team",
                icon: <Work className="list-icon" />,
                url: "/about-us",
            },
        ],
    },
    {
        id: 3,
        title: "Huduma zetu",
        icon: <Work className="list-icon" />,
        url: "/what-we-do",
        subLinks: [
            {
                id: 1,
                title: "Our products",
                icon: <Work className="list-icon" />,
                url: "/what-we-do",
            },
            {
                id: 2,
                title: "Spare part retailing",
                icon: <Work className="list-icon" />,
                url: "/what-we-do",
            },
            {
                id: 3,
                title: "Machine field training",
                icon: <Work className="list-icon" />,
                url: "/what-we-do",
            },
            {
                id: 4,
                title: "After sell service",
                icon: <Work className="list-icon" />,
                url: "/what-we-do",
            },
        ],
    },
    {
        id: 4,
        title: "Matunzi",
        icon: <CameraAltOutlined className="list-icon" />,
        url: "/gallaries",
    },
    {
        id: 5,
        title: "Wakala wetu",
        icon: <Group className="list-icon" />,
        url: "/our-dealers",
    },
    {
        id: 6,
        title: "Mawasiliano",
        icon: <PhoneForwarded className="list-icon" />,
        url: "/contact-us",
    },
];
