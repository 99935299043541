import * as React from "react";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import HotToastfy from "../../components/HotToastfy";
import SideBar from "../../components/SideBar";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const AppLayout = ({ children }) => {
    // ############# Const ##############
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const { pathname } = useLocation();

    // ############# FUNC ##############
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Box>
            <HotToastfy />
            {/* ############### Navbar ############### */}
            <Navbar
                handleDrawerToggle={handleDrawerToggle}
                mobileOpen={mobileOpen}
            />

            {/* ############### sidebar ###############*/}
            <SideBar
                mobileOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
            />

            {/* ############### content ###############*/}
            <Box component="main" sx={{ flexGrow: 1 }}>
                {children}

                {/* ############## Footer ############## */}
                <Footer />
            </Box>
        </Box>
    );
};

export default AppLayout;
