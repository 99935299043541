import { Box, List, ListItem, ListItemButton, Typography } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { colors } from '../hooks/useColor';
import { navbarLinks, navbarLinksSwahili } from '../utils/navbarLinks';
import { useSelector } from 'react-redux';
import { systemConfigSelector } from '../states/features/selectors';

const NavbarLinks = () => {
    // ############ Redux state ##############
    const { language } = useSelector(systemConfigSelector);
    const links = language === 'swahili' ? navbarLinksSwahili : navbarLinks;
    return (
        <>
            <Box>
                <List
                    sx={{
                        'position': 'relative',
                        'bottom': 0,
                        'right': 0,
                        'left': 0,
                        'p': 0,
                        'display': { md: 'flex', xs: 'none' },
                        'justifyContent': 'space-between',
                        'alignItems': 'center',
                        '& .item': {
                            'display': 'flex',
                            'flexDirection': 'column',
                            '& .btn': {
                                display: 'flex',
                                p: 0,
                                textAlign: 'center',
                                alignItems: 'center',
                            },
                            '& .icon': {
                                opacity: 0.8,
                                fontSize: 20,
                                mr: 1,
                            },
                            '& .title': {
                                opacity: 0.8,
                                fontWeight: 'bold',
                                color: colors.primary,
                                textTransform: 'capitalize',
                            },
                        },
                    }}
                >
                    {links?.map((link) => (
                        <NavLink
                            to={link.url}
                            style={{ width: '100%' }}
                            key={link.id}
                        >
                            {({ isActive }) => (
                                <ListItem className="item">
                                    <ListItemButton
                                        className="btn"
                                        sx={{
                                            color: isActive
                                                ? colors.secondary
                                                : colors.textColor,
                                        }}
                                    >
                                        <Typography
                                            noWrap
                                            className="title"
                                            sx={{
                                                color: '#fff',
                                                fontSize: 11,
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            {link.title}
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            )}
                        </NavLink>
                    ))}
                </List>
            </Box>
        </>
    );
};

export default NavbarLinks;
