import {
    LocationOnOutlined,
    Person3Outlined,
    PhoneInTalk,
} from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../hooks/useColor';

const AgentWidget = ({ agent }) => {
    return (
        <>
            <Box sx={{ overflow: 'hidden' }}>
                <Box sx={{ height: 300, position: 'relative' }}>
                    <img
                        src={`${agent.img}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`${agent.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt={agent.title}
                        loading="lazy"
                        style={{ height: `100%`, width: '100%' }}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: `rgba(0,0,0,0.3)`,
                        }}
                    ></Box>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 10,
                            left: 10,
                            width: '100%',
                            px: { md: 5, xs: 2 },
                            py: 2,
                        }}
                    >
                        <Typography
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: { md: 35, xs: 25 },
                                color: colors.white,
                                fontWeight: 'bold',
                                mb: 1,
                            }}
                        >
                            <LocationOnOutlined
                                sx={{
                                    fontSize: { md: 50, xs: 35 },
                                    color: colors.white,
                                    mr: 2,
                                }}
                            />
                            {agent.location}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ bgcolor: colors.primary, px: 5, py: 1 }}>
                    <Typography
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 25,
                            color: colors.white,
                            fontWeight: 'bold',
                            mb: 1,
                        }}
                    >
                        Wakala
                    </Typography>
                </Box>
                <Box
                    sx={{
                        bgcolor: colors.secondary,
                        display: 'flex',
                        alignItems: 'center',
                        px: { md: 5, xs: 2 },
                        py: 2,
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: { md: 25, xs: 20 },
                                color: colors.primary,
                                fontWeight: 'bold',
                                mb: 1,
                            }}
                        >
                            <Person3Outlined
                                sx={{
                                    fontSize: { md: 40, xs: 35 },
                                    color: colors.white,
                                    mr: 2,
                                }}
                            />
                            {agent.name}
                        </Typography>
                        <Typography
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: { md: 25, xs: 20 },
                                color: colors.primary,
                                fontWeight: 'bold',
                            }}
                        >
                            <PhoneInTalk
                                sx={{
                                    fontSize: { md: 40, xs: 35 },
                                    color: colors.white,
                                    mr: 2,
                                }}
                            />
                            {agent.phone}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default AgentWidget;
