import useColor from './useColor';
import { useSelector } from 'react-redux';
import { systemConfigSelector } from '../states/features/selectors';
import { createTheme } from '@mui/material';

const useTheme = () => {
    // ######### Inner states #############
    let theme = null;

    // ######### CONST #############
    const colors = useColor();

    // ######### Redux states #############
    const { darkMode } = useSelector(systemConfigSelector);

    // => Light theme
    const primaryTheme = createTheme({
        palette: {
            primary: {
                main: colors.primary,
            },
            secondary: {
                main: colors.secondary,
            },
        },
    });

    // => Dark theme
    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: colors.primary,
            },
            secondary: {
                main: colors.secondary,
            },
        },
    });

    if (darkMode) {
        theme = darkTheme;
    } else {
        theme = primaryTheme;
    }

    return theme;
};

export default useTheme;
