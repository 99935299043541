import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import { Avatar, Box, Grid, Stack, styled } from "@mui/material";
import logo from "../assets/media/images/logo.png";
import { Link } from "react-router-dom";
import { colors } from "../hooks/useColor";
// import { navbarLinks, navbarLinksSwahili } from "../utils/navbarLinks";
import TopBar from "./TopBar";
// import { systemConfigSelector } from "../states/features/selectors";
// import { useSelector } from "react-redux";
import CustomNavbar from "./CustomNavbar";

// ################# TOPBAR RIGHT ###############
const TopbarRight = styled(Stack)({});

// !###################### MAIN FUNC ############################
const Navbar = ({ mobileOpen, handleDrawerToggle }) => {
    // ############ Redux state ##############
    // const { language } = useSelector(systemConfigSelector);
    // const links = language === "swahili" ? navbarLinksSwahili : navbarLinks;
    return (
        <>
            <Box sx={{ bgcolor: colors.primary, zIndex: 1500 }}>
                <Grid container spacing={2}>
                    <Grid item sm={2} xs={12}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                bgcolor: colors.primary,
                                height: "100%",
                            }}
                        >
                            <Link to={"/"}>
                                <Avatar
                                    src={logo}
                                    variant="square"
                                    sx={{
                                        height: { md: 65, sm: 65, xs: 55 },
                                        width: "100%",
                                        p: 1,
                                        img: {
                                            objectFit: "cover",
                                        },
                                    }}
                                />
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item sm={10} xs={12}>
                        <Box>
                            <Box
                                sx={{
                                    bgcolor: colors.primary,
                                }}
                            >
                                <TopBar
                                    mobileOpen={mobileOpen}
                                    handleDrawerToggle={handleDrawerToggle}
                                />
                            </Box>
                            <Box
                                sx={{
                                    width: "100%",
                                    bgcolor: colors.primary,
                                    display: {
                                        xs: "none",
                                        sm: "block",
                                        md: "block",
                                    },
                                }}
                            >
                                <Toolbar
                                    sx={{
                                        justifyContent: "space-between",
                                        bgcolor: colors.primary,
                                    }}
                                >
                                    {/* ################### CENTER ################# */}
                                    <Box sx={{ textAlign: "center" }}></Box>

                                    {/* ################### RIGHT ################# */}
                                    <TopbarRight
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{px: 5}}
                                    >
                                        <CustomNavbar />
                                    </TopbarRight>
                                </Toolbar>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Navbar;
