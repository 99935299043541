import {
    ListItem,
    ListItemButton,
    ListItemText,
    Typography,
} from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { colors } from '../../hooks/useColor';

const CustomListItem = ({ desktopOpen, text, url }) => {
    return (
        <React.Fragment>
            <NavLink to={url}>
                {({ isActive }) => (
                    <ListItem disablePadding>
                        <ListItemButton
                            sx={{
                                'borderRight': isActive
                                    ? `4px solid ${colors.primary}`
                                    : undefined,
                                'height': 38,
                                'justifyContent': desktopOpen
                                    ? 'initial'
                                    : 'center',
                                'px': 2.5,
                                '&:hover': {
                                    bgcolor: colors.primary,
                                },
                            }}
                        >
                            <ListItemText
                                disableTypography
                                primary={
                                    <Typography sx={{ color: "#fff", fontSize: 20 }}>
                                        {text}
                                    </Typography>
                                }
                                sx={{
                                    '& .MuiTypography-root': {
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        color: colors.textColor,
                                    },
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                )}
            </NavLink>
        </React.Fragment>
    );
};

export default CustomListItem;
