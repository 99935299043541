import { createSlice } from "@reduxjs/toolkit";
import { categoriesDummyData } from "../../dummyData/categoriesDummyData";

// => initialState
const initialState = {
    categories: categoriesDummyData,
};
export const categoriesSlice = createSlice({
    name: "categoriesSlice",
    initialState,
    reducers: {
        setCategories: (state, action) => {
            state.categories = action.payload;
        },
    },
});

export const { setCategories } = categoriesSlice.actions;
export default categoriesSlice.reducer;
