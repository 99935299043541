import React from "react";
import PageBanner from "../../components/PageBanner";
import bgImage from "../../assets/media/images/general/dealers.jpg";
import { Box, Grid, Typography } from "@mui/material";
import SpacingContent from "../../components/SpacingContent";
import AgentWidget from "../../components/widgets/AgentWidget";
import agent1 from "../../assets/media/images/agents/agent1.png";
import agent2 from "../../assets/media/images/agents/agent2.png";
import agent3 from "../../assets/media/images/agents/agent3.png";
import agent4 from "../../assets/media/images/agents/agent4.png";
import agent8 from "../../assets/media/images/agents/agent8.png";
import agent9 from "../../assets/media/images/agents/agent9.png";
import agent10 from "../../assets/media/images/agents/agent10.png";
import agent11 from "../../assets/media/images/agents/agent11.png";
import agent12 from "../../assets/media/images/agents/agent12.png";
import agent13 from "../../assets/media/images/agents/agent3.png";
import agent14 from "../../assets/media/images/agents/agent4.png";
import rukwa from "../../assets/media/images/agents/rukwa.jpg";
import songwee from "../../assets/media/images/agents/songwee.jpg";
import sinza from "../../assets/media/images/agents/sinza.jpg";
import tabora from "../../assets/media/images/agents/tabora.jpg";
import mbeya from "../../assets/media/images/agents/mbeya.jpg";
import { systemConfigSelector } from "../../states/features/selectors";
import { useSelector } from "react-redux";
import { colors } from "../../hooks/useColor";

const WhatWeDo = () => {
    // ############ Redux state ##############
    const { language } = useSelector(systemConfigSelector);

    const agents = [
        {
            id: 1,
            name: "John Chacha Wambura",
            location: "Tabora, Igunga",
            phone: "+255 659 581 458",
            img: agent1,
        },
        {
            id: 2,
            name: "Herry Minja",
            location: "Morogoro, Mvomero Ifakara",
            phone: "+255 655 668 242",
            img: agent2,
        },
        {
            id: 3,
            name: "Job Mdeluka",
            location: "Iringa, Mafinga",
            phone: "+255 754 915 626",
            img: agent3,
        },
        {
            id: 4,
            name: "Sunde Tractors spares",
            location: "Morogoro town,",
            phone: "+255 652 114 720",
            img: agent4,
        },
        {
            id: 5,
            name: "Haroun Mgandilwa",
            location: "Mbeya, Mbarali Ubaruku",
            phone: "+255 758 564 606",
            img: mbeya,
        },
        {
            id: 6,
            name: "Steven Mwampwani",
            location: "Mbeya, Mbarali Chimala Apex",
            phone: "+255 764 734 804",
            img: mbeya,
        },
        {
            id: 7,
            name: "Medrack Mheza",
            location: "Mbeya, Mbarali Chimala",
            phone: "+255 715 660 720",
            img: mbeya,
        },
        {
            id: 8,
            name: "John Luig Mbuya",
            location: "Dodoma, Kibaigwa",
            phone: "+255 763 776486",
            img: agent8,
        },
        {
            id: 9,
            name: "Methodi M. Pilli",
            location: "Ruvuma, Tunduru",
            phone: "+255 628 733 757",
            img: agent9,
        },
        {
            id: 10,
            name: "Tatu Gishepo Shabani",
            location: "Simiyu, Meatu",
            phone: "+255 756 939 877",
            img: agent10,
        },
        {
            id: 11,
            name: "Mabula Madoshi Mwandiki",
            location: "Simiyu, Bariadi",
            phone: "+255 628 733 757",
            img: agent11,
        },
        {
            id: 12,
            name: "Bakari Amiri Feruzi",
            location: "Manyara, Magugu",
            phone: "+255 765 139 974",
            img: agent12,
        },
        {
            id: 13,
            name: "Kiboko Machinery",
            location: "Shinyanga, Kahama",
            phone: "+255 787 123 724",
            img: agent13,
        },
        {
            id: 14,
            name: "Taha Hussein Jafferjee",
            location: "Morogoro",
            phone: "+255 655 758 055",
            img: agent14,
        },
        {
            id: 15,
            name: "Ramadhani Hassani Nyanga(Harax motors)",
            location: "Mwanza,kwimba",
            phone: "+255 787 823 729",
            img: mbeya,
        },
        {
            id: 16,
            name: "Joga spare parts,",
            location: "Dumila, morogoro,",
            phone: "+255 715 066 596",
            img: songwee,
        },
        {
            id: 17,
            name: "Safari Macheniries,",
            location: "Shinyanga ,town",
            phone: "+255 784 301 555",
            img: rukwa,
        },
        {
            id: 18,
            name: "Fredy lazaro mjoge,",
            location: "Morogoro,mlimba",
            phone: "+255 784 368 044",
            img: tabora,
        },
        {
            id: 21,
            name: "Agrizana (mathias lusendamila)",
            location: "Morogoro, town",
            phone: "+255 768 206 020",
            img: tabora,
        },
        {
            id: 22,
            name: "Meher International T Ltd",
            location: "Tazara, Dar es salaam",
            phone: "+255 754 786 000",
            img: tabora,
        },
        {
            id: 19,
            name: "Ekambe Spares",
            location: "Singida, Town",
            phone: "+255 755 811 571",
            img: agent14,
        },
        {
            id: 20,
            name: "Zhumae Technology",
            location: "Dar es Salaam, Sinza",
            phone: "+255 767 496 077",
            img: sinza,
        },
    ];
    return (
        <>
            <PageBanner bgImage={bgImage} coverPer={0.3}>
                <Grid container spacing={2} padding={2} sx={{ mt: 5 }}>
                    <Grid item sm={6} xs={12}>
                        <Typography
                            className="mh-font"
                            sx={{
                                fontSize: { md: 65, xs: 40 },
                                fontWeight: "bold",
                                color: colors.primary,
                                lineHeight: 1,
                            }}
                        >
                            {language === "swahili"
                                ? "TUPO TANZANIA NZIMA TAYARI KUKUHUDUMIA"
                                : "you can get all our products allover Tanzania through our dealers"}
                        </Typography>
                    </Grid>
                </Grid>
            </PageBanner>
            <Box>
                <SpacingContent>
                    <Grid container spacing={3}>
                        {agents.map((agent) => (
                            <Grid item sm={6} key={agent.id}>
                                <AgentWidget agent={agent} />
                            </Grid>
                        ))}
                    </Grid>
                </SpacingContent>
            </Box>
        </>
    );
};

export default WhatWeDo;
