import React from 'react';
import contactUs from '../assets/media/images/contactUs.png';
import { Box, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import CustomTextField from './forms/CustomTextField';
import { colors } from '../hooks/useColor';
import CustomSubmitButton from './forms/CustomSubmitButton';
import CustomMultlineTextField from './forms/CustomMultlineTextField';
import { useSelector } from 'react-redux';
import { systemConfigSelector } from '../states/features/selectors';

const RequestQuote = () => {
    // ############ Redux state ##############
    const { language } = useSelector(systemConfigSelector);
    return (
        <>
            <Box
                sx={{
                    height: { md: '100vh', xs: '90vh' },
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundImage: `url(${contactUs})`,
                    backgroundPosition: 'center left',
                    backgroundSize: { md: '100%', xs: '700px 500px' },
                    backgroundRepeat: 'no-repeat',
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={{
                        height: { md: '100vh', xs: '90vh' },
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        background: `rgb(21, 110, 72, .2)`,
                    }}
                >
                    <Grid
                        container
                        justifyContent={'flex-end'}
                        spacing={3}
                        padding={3}
                    >
                        <Grid item sm={5} xs={12}>
                            <Box>
                                <Typography
                                    className="mh-font"
                                    sx={{
                                        fontSize: { md: 65, xs: 20 },
                                        color: colors.white,
                                        fontWeight: 'bold',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    {language === 'swahili'
                                        ? 'Omba ankara  leo!'
                                        : 'Request a quote today !'}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: { md: 16, xs: 12 },
                                        color: colors.white,
                                        mb: 1,
                                    }}
                                >
                                    {language === 'swahili'
                                        ? 'jaza fomu hapa chini na tutakujibu kwa nukuu maalum kwa mahitaji yako ya kilimo'
                                        : 'Fill a form below and we will reply with custom quote for your agricultural needs.'}
                                </Typography>
                                <Typography
                                    className="mh-font"
                                    sx={{
                                        fontSize: { md: 14, xs: 10 },
                                        color: colors.white,
                                    }}
                                >
                                    {language === 'swahili'
                                        ? 'Elezea mashine yako ya kilimo uitakayo'
                                        : 'Describe your agricultural machine'}
                                </Typography>
                            </Box>
                            <Box>
                                <Formik
                                    initialValues={{
                                        name: '',
                                        email: '',
                                        message: '',
                                    }}
                                >
                                    {(formik) => (
                                        <Form>
                                            <Grid container columnSpacing={4}>
                                                <Grid item sm={6} xs={12}>
                                                    <CustomTextField
                                                        name={'name'}
                                                        type={'text'}
                                                        label={
                                                            language ===
                                                            'swahili'
                                                                ? 'jina'
                                                                : 'name'
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <CustomTextField
                                                        name={'email'}
                                                        type={'email'}
                                                        label={
                                                            language ===
                                                            'swahili'
                                                                ? 'barua pepe'
                                                                : 'email'
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item sm={12} xs={12}>
                                                    <CustomMultlineTextField
                                                        name={'Message'}
                                                        type={'text'}
                                                        label={
                                                            language ===
                                                            'swahili'
                                                                ? 'ujumbe'
                                                                : 'Message'
                                                        }
                                                        rows={4}
                                                    />
                                                </Grid>
                                                <Grid item sm={12} xs={12}>
                                                    <CustomSubmitButton
                                                        btnColor={'error'}
                                                    >
                                                        {language === 'swahili'
                                                            ? 'wasilisha'
                                                            : 'Submit'}
                                                    </CustomSubmitButton>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default RequestQuote;
