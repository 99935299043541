import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../hooks/useColor";

const Section = ({ head, subHead, children, pTop, bBottom, bgColor }) => {
    return (
        <>
            <Box
                sx={{
                    pt: { md: pTop ? 0 : 20, xs: 6 },
                    pb: { md: bBottom ? 0 : 20, xs: 6 },
                    background: bgColor && bgColor,
                }}
            >
                <Grid
                    container
                    justifyContent={"center"}
                    rowSpacing={{ md: 8, xs: 0 }}
                >
                    {head && (
                        <Grid item sm={6} xs={8}>
                            <Box>
                                <Typography
                                    className="mh-font"
                                    sx={{
                                        fontSize: { md: 45, xs: 18 },
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        color: colors.primary,
                                        opacity: 0.8,
                                        textTransform: "uppercase",
                                        position: "relative",
                                        display: "flex",
                                        justifyContent: "center",
                                        ":before": {
                                            content: "''",
                                            height: 70,
                                            width: { md: 200, xs: 25 },
                                            position: "absolute",
                                            borderBottom: `5px solid red`,
                                            bottom: -10,
                                        },
                                    }}
                                >
                                    {head}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        textAlign: "center",
                                        color: colors.textColor,
                                        opacity: 0.8,
                                    }}
                                >
                                    {subHead}
                                </Typography>
                            </Box>
                        </Grid>
                    )}

                    <Grid item sm={12} xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Section;
