import React from 'react';
import { Box } from '@mui/material';
import SpacingContent from './SpacingContent';

const PageBanner = ({ bgImage, height, coverPer, children }) => {
    return (
        <>
            <Box sx={{ display: { md: "flex", xs: "block", zIndex: 999 } }}>
                <Box
                    sx={{
                        height: height
                            ? height
                            : {
                                  md: "100vh",
                                  sm: "100vh",
                                  xs: "80vh",
                              },
                        width: "100vw",
                        display: "flex",
                        alignItems: "center",
                        backgroundImage: `url(${bgImage})`,
                        backgroundPosition: {
                            md: "center top",
                            sm: "center top",
                            xs: "center right",
                        },
                        backgroundSize: {
                            md: "100% 120%",
                            xs: "700px 80vh",
                        },
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <Box
                        sx={{
                            height: height ? height : "100vh",
                            width: "100vw",
                            display: "flex",
                            alignItems: "center",
                            background: `rgb(21, 110, 72, ${
                                coverPer ? coverPer : 0.1
                            })`,
                        }}
                    >
                        <SpacingContent>{children}</SpacingContent>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default PageBanner;
