import React from 'react';
import { Avatar, Box, Grid, Typography, styled } from '@mui/material';
import f1 from '../assets/media/images/f1.png';
import f2 from '../assets/media/images/f2.png';
// import { ArrowRight } from '@mui/icons-material';
import { colors } from '../hooks/useColor';
// import { NavLink } from 'react-router-dom';
import NavbarLinks from './NavbarLinks';
// import NewsLetter from './NewsLetter';
import SocialLinkWidget from './widgets/SocialLinkWidget';

import instagram from '../assets/media/images/social/instagram.png';
import facebook from '../assets/media/images/social/facebook.png';
import twitter from '../assets/media/images/social/twitter.png';
import linkedin from '../assets/media/images/social/linkedin.png';
import youtube from '../assets/media/images/social/youtube.png';
import tiktok from '../assets/media/images/social/tiktok.png';
import { systemConfigSelector } from '../states/features/selectors';
import { useSelector } from 'react-redux';

// const LinkWidget = styled(Typography)({
//     'color': colors.secondary,
//     'fontSize': 13,
//     'position': 'relative',
//     'display': 'flex',
//     'alignItems': 'center',
//     'marginBottom': 5,
//     '& .icon': {
//         fontSize: 15,
//     },
//     ':before': {
//         content: "''",
//         position: 'absolute',
//         height: 5,
//         width: 60,
//         bottom: 0,
//         left: 10,
//         borderBottom: `1px dotted ${colors.secondary}`,
//     },
// });

const Footer = () => {
    const date = new Date();

    // ############ Redux state ##############
    const { language } = useSelector(systemConfigSelector);

    // const links1 = [
    //     {
    //         id: 1,
    //         name:
    //             language === 'swahili'
    //                 ? 'Historia ya Biashara Yetu'
    //                 : 'Our brand history',
    //         url: '',
    //     },
    //     {
    //         id: 2,
    //         name:
    //             language === 'swahili'
    //                 ? 'maono, dhamira na maadili'
    //                 : 'Vision, Mission, & Values',
    //         url: '',
    //     },
    //     {
    //         id: 3,
    //         name:
    //             language === 'swahili'
    //                 ? 'Timu ya Usimamizi'
    //                 : 'Management team',
    //         url: '',
    //     },
    // ];
    // const links2 = [
    //     {
    //         id: 1,
    //         name: language === 'swahili' ? 'Bidhaa Zetu' : 'Our products',
    //         url: '',
    //     },
    //     {
    //         id: 2,
    //         name:
    //             language === 'swahili'
    //                 ? 'Uuzaji wa vipuri'
    //                 : 'Spare part retailing',
    //         url: '',
    //     },
    //     {
    //         id: 3,
    //         name:
    //             language === 'swahili'
    //                 ? 'Mafunzo ya Mashine'
    //                 : 'Machine field training',
    //         url: '',
    //     },
    //     {
    //         id: 4,
    //         name:
    //             language === 'swahili'
    //                 ? 'Baada ya Kuuza huduma'
    //                 : 'After sell service',
    //         url: '',
    //     },
    // ];
    const socialLinks = [
        {
            id: 3,
            title: 'Instagram',
            url: 'https://www.instagram.com/zanaboralimited/',
            iconColor: 'brown',
            icon: instagram,
        },
        {
            id: 2,
            title: 'Facebook',
            url: 'https://www.facebook.com/Zanaboralimited/',
            iconColor: 'blue',
            icon: facebook,
        },
        {
            id: 4,
            title: 'Twitter',
            url: 'https://twitter.com/zanabora_ltd',
            iconColor: '#0077b6',
            icon: twitter,
        },
        {
            id: 6,
            title: 'YouTube',
            url: 'https://www.youtube.com/@zanaboralimited1130/videos',
            iconColor: 'red',
            icon: youtube,
        },
        {
            id: 5,
            title: 'LinkedIn',
            url:
                'https://www.linkedin.com/company/zanaboralimited/?viewAsMember=true',
            iconColor: '#0077b6',
            icon: linkedin,
        },
        {
            id: 1,
            title: 'TikTok',
            url: 'https://www.tiktok.com/@zanaboralimited',
            iconColor: 'green',
            icon: tiktok,
        },
    ];
    return (
        <>
            {/* ################ NewsLetter ################ */}
            {/* <NewsLetter /> */}
            <Box
                sx={{
                    mt: 5,
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3})`,
                }}
            >
                <Box>
                    <Grid
                        container
                        justifyContent={'center'}
                        paddingY={5}
                        paddingX={{ md: 0, xs: 5 }}
                        rowSpacing={5}
                        sx={{ position: 'relative', overflow: 'hidden' }}
                    >
                        <Grid item sm={11} xs={12}>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Avatar
                                    src={f1}
                                    variant="square"
                                    sx={{
                                        position: 'absolute',
                                        right: 0,
                                        left: -50,
                                        width: { md: 800, xs: '100%' },
                                        height: 300,
                                        bottom: 0,
                                        top: { md: 0, xs: 100 },
                                        zIndex: 0,
                                        opacity: 0.3,
                                        img: {
                                            objectFit: 'contain',
                                        },
                                    }}
                                />
                                <Avatar
                                    src={f2}
                                    variant="square"
                                    sx={{
                                        position: 'absolute',
                                        right: 0,
                                        width: { md: 800, xs: '100%' },
                                        height: 300,
                                        bottom: 0,
                                        top: { md: 120, xs: 100 },
                                        zIndex: 0,
                                        opacity: 0.3,
                                        img: {
                                            objectFit: 'contain',
                                        },
                                    }}
                                />
                                {/* <Box
                                    sx={{
                                        width: '100%',
                                        zIndex: 10,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            fontWeight: 'bold',
                                            color: colors.primary,
                                            mb: 1,
                                        }}
                                    >
                                        Quick links
                                    </Typography>
                                    <Grid container>
                                        <Grid item sm={3} xs={6}>
                                            {links2.map((link) => (
                                                <NavLink
                                                    to={link.url}
                                                    key={link.id}
                                                >
                                                    <LinkWidget>
                                                        <ArrowRight className="icon" />
                                                        {link.name}
                                                    </LinkWidget>
                                                </NavLink>
                                            ))}
                                        </Grid>
                                        <Grid item sm={3} xs={6}>
                                            {links1.map((link) => (
                                                <NavLink
                                                    to={link.url}
                                                    key={link.id}
                                                >
                                                    <LinkWidget>
                                                        <ArrowRight className="icon" />
                                                        {link.name}
                                                    </LinkWidget>
                                                </NavLink>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Box> */}
                                <Box>
                                    <NavbarLinks />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            sm={11}
                            xs={12}
                            sx={{
                                zIndex: 10,
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    display: { md: 'flex', xs: 'block' },
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Box>
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                mb: 1,
                                                color: colors.primary,
                                            }}
                                        >
                                            {language === 'swahili'
                                                ? 'S.L.P 2740, Dar es Salaam - Tanzania'
                                                : 'P. O. Box 2740, Dar es Salaam - Tanzania'}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                mb: 1,
                                                color: colors.primary,
                                            }}
                                        >
                                            {language === 'swahili'
                                                ? 'Kitalu Na 2118/98, Ghorofa Na 002, Rajani Building, Indira Gandhi St'
                                                : 'Plot No 2118/98, Apartment No 002, Rajani Building, Indira Gandhi St'}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <a
                                            href="tel:+255 686 170 833"
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 'bold',
                                                color: colors.primary,
                                            }}
                                        >
                                            +255 686 170 833
                                        </a>{' '}
                                        | {''}
                                        <a
                                            href="tel:+255 658 331 633"
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 'bold',
                                                color: colors.primary,
                                            }}
                                        >
                                            +255 658 331 633
                                        </a>{' '}
                                        | {''}
                                        <a
                                            href="tel:+255 222 126 174"
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 'bold',
                                                color: colors.primary,
                                            }}
                                        >
                                            +255 222 126 174
                                        </a>{' '}
                                        | {''}
                                        <a
                                            href="tel:+255 752 331 633"
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 'bold',
                                                color: colors.primary,
                                            }}
                                        >
                                            +255 752 331 633
                                        </a>
                                    </Box>
                                </Box>
                                <Box>
                                    <Grid
                                        container
                                        spacing={2}
                                        justifyContent={{
                                            md: 'flex-end',
                                            xs: 'center',
                                        }}
                                        paddingRight={5}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Grid item sm={3} xs={12}>
                                            <Typography
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: colors.primary,
                                                }}
                                            >
                                                @zanaboralimited
                                            </Typography>
                                        </Grid>
                                        {socialLinks?.map((link) => (
                                            <Grid
                                                item
                                                sm={1}
                                                xs={2}
                                                key={link.id}
                                            >
                                                <SocialLinkWidget link={link} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                {/* ################# MINI Footer ############### */}
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <Box
                        sx={{
                            background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary})`,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            p: 2,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                color: colors.bgColor3,
                                textTransform: 'capitalize',
                                textAlign: 'center',
                            }}
                        >
                            {language === 'swahili'
                                ? `Hakimiliki © ${date.getFullYear()} Zana Bora Limited. Haki zote zimehifadhiwa.`
                                : `Copyright © ${date.getFullYear()} zana bora limited. All rights reserved`}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default Footer;
