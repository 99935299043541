import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductWidget from "../../components/widgets/ProductWidget";
import { useSelector } from "react-redux";
import { categoriesSelector } from "../../states/features/selectors";
import { useParams } from "react-router-dom";
import { filter } from "lodash";
import PageBanner from "../../components/PageBanner";
import { colors } from "../../hooks/useColor";

const CategoryDetails = () => {
    // ############ CONST #############
    const { category_id } = useParams();
    const { categories } = useSelector(categoriesSelector);

    // ########### Comp state ############
    const [category, setCategory] = useState(null);

    // ############ FUNCT #############
    const getCategoryDetails = () => {
        let details = filter(categories, { id: parseInt(category_id) });
        setCategory(details[0]);
    };
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            getCategoryDetails();
        }

        return () => {
            isSubscribed = false;
        };
    }, [category_id]);

    return (
        <>
            <PageBanner height={"40vh"}>
                <Grid container justifyContent={"center"} spacing={10}>
                    <Grid item sm={10}>
                        <Typography
                            sx={{
                                fontSize: 45,
                                textAlign: "center",
                                py: 3,
                                color: colors.primary,
                                fontWeight: "bold",
                            }}
                        >
                            {`${category?.name} products`}
                        </Typography>
                    </Grid>
                </Grid>
            </PageBanner>
            <Box>
                <Grid container spacing={2}>
                    {category?.products.map((product) => (
                        <Grid item sm={4} xs={12} key={product.id}>
                            <ProductWidget product={product} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
};

export default CategoryDetails;
