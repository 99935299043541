import React from 'react';
import { Avatar, Box, IconButton } from '@mui/material';

const SocialLinkWidget = ({ link }) => {
    return (
        <>
            <a href={link.url} target="_blank" rel="noopener noreferrer">
                <Box
                    sx={{
                        'display': 'flex',
                        'alignItems': 'center',
                        'justifyContent': 'center',
                        'width': { md: 60, xs: 40 },
                        'height': { md: 60, xs: 40 },
                        'borderRadius': 5,
                        'overflow': 'hidden',
                        '& .title': {
                            fontSize: 14,
                            fontWeight: 'bold',
                        },
                        ':hover': {
                            '& .icon-con': {
                                transform: `scale(1.1)`,
                                transition: `.3s all ease-in-out`,
                            },
                        },
                    }}
                >
                    <Box className={'icon-con'}>
                        <IconButton>
                            <Avatar
                                variant="square"
                                src={link.icon}
                                sx={{ img: { objectFit: 'contain' } }}
                            />
                        </IconButton>
                    </Box>
                    {/* <Typography className="title">{link.title}</Typography> */}
                </Box>
            </a>
        </>
    );
};

export default SocialLinkWidget;
