import React from "react";
import PageBanner from "../../components/PageBanner";
import bgImage from "../../assets/media/images/gallary/g14.png";
import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../hooks/useColor";
import SpacingContent from "../../components/SpacingContent";
import { DraftsOutlined, LocationOnOutlined, Phone } from "@mui/icons-material";
import { Form, Formik } from "formik";
import CustomTextField from "../../components/forms/CustomTextField";
import CustomMultlineTextField from "../../components/forms/CustomMultlineTextField";
import CustomSubmitButton from "../../components/forms/CustomSubmitButton";

import instagram from "../../assets/media/images/social/instagram.png";
import facebook from "../../assets/media/images/social/facebook.png";
import twitter from "../../assets/media/images/social/twitter.png";
import linkedin from "../../assets/media/images/social/linkedin.png";
import youtube from "../../assets/media/images/social/youtube.png";
import tiktok from "../../assets/media/images/social/tiktok.png";
import SocialLinkWidget from "../../components/widgets/SocialLinkWidget";
import { systemConfigSelector } from "../../states/features/selectors";
import { useSelector } from "react-redux";

const ContactUs = () => {
    // ############ Redux state ##############
    const { language } = useSelector(systemConfigSelector);

    const phoneNumbers = [
        { phone: "+255 686 170 833" },
        { phone: "+255 658 331 633" },
        { phone: "+255 752 331 633" },
    ];
    const socialLinks = [
        {
            id: 3,
            title: "Instagram",
            url: "https://www.instagram.com/zanaboralimited/",
            iconColor: "brown",
            icon: instagram,
        },
        {
            id: 2,
            title: "Facebook",
            url: "https://www.facebook.com/Zanaboralimited/",
            iconColor: "blue",
            icon: facebook,
        },
        {
            id: 4,
            title: "Twitter",
            url: "https://twitter.com/zanabora_ltd",
            iconColor: "#0077b6",
            icon: twitter,
        },
        {
            id: 6,
            title: "YouTube",
            url: "https://www.youtube.com/@zanaboralimited1130/videos",
            iconColor: "red",
            icon: youtube,
        },
        {
            id: 5,
            title: "LinkedIn",
            url: "https://www.linkedin.com/company/zanaboralimited/?viewAsMember=true",
            iconColor: "#0077b6",
            icon: linkedin,
        },
        {
            id: 1,
            title: "TikTok",
            url: "https://www.tiktok.com/@zanaboralimited",
            iconColor: "green",
            icon: tiktok,
        },
    ];
    return (
        <>
            <PageBanner bgImage={bgImage} coverPer={0.5}>
                <Grid container spacing={2} padding={2} sx={{ mt: 5 }}>
                    <Grid item sm={7} xs={12}>
                        <Typography
                            className="mh-font"
                            sx={{
                                fontSize: { md: 45, xs: 40 },
                                fontWeight: "bold",
                                color: colors.bgColor6,
                                lineHeight: 1,
                            }}
                        >
                            {language === "swahili"
                                ? "Wasiliana nasi"
                                : "Hello! Uncover excellence with Zana Bora Limited. Require assistance or information? Don't hesitate to contact us - we're at your service!"}
                        </Typography>
                        {/* <Typography
                            sx={{
                                fontSize: { md: 20, xs: 16 },
                                color: colors.white,
                                lineHeight: 1,
                                my: 2,
                            }}
                        >
                            {language === "swahili"
                                ? "Kwa mahitaji yako yote ya mashine za kilimo, vipuri, baada ya huduma za mauzo na mikopo ya mashine za kilimo, wasiliana na timu ya 'zana' leo!"
                                : "For all your needs of agricultural machinery, spare parts, after sale services and agricultural machinery loans, contact the 'zana' team today!"}
                        </Typography> */}
                    </Grid>
                </Grid>
            </PageBanner>
            <Box>
                <SpacingContent>
                    <Grid container justifyContent={"center"}>
                        <Grid item sm={4}>
                            <Box
                                sx={{
                                    height: 800,
                                    mt: -10,
                                    mb: 10,
                                    display: "flex",
                                    alignItems: "center",
                                    p: 4,
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    background: `linear-gradient(45deg, ${colors.primary}, ${colors.secondary})`,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        borderBottom: `2px solid ${colors.secondary}`,
                                        width: "100%",
                                    }}
                                >
                                    <Box>
                                        <DraftsOutlined
                                            sx={{
                                                fontSize: { md: 60, xs: 40 },
                                                color: colors.white,
                                                mr: 2,
                                            }}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: 22,
                                                fontWeight: "bold",
                                                color: colors.white,
                                            }}
                                        >
                                            info@zana.co.tz
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        pb: 8,
                                        width: "100%",
                                        display: "flex",
                                        borderBottom: `2px solid ${colors.secondary}`,
                                    }}
                                >
                                    <Box>
                                        <Phone
                                            sx={{
                                                fontSize: { md: 60, xs: 40 },
                                                color: colors.white,
                                                mr: 2,
                                            }}
                                        />
                                    </Box>
                                    <Box>
                                        {phoneNumbers.map((phone) => (
                                            <Typography
                                                key={phone.phone}
                                                sx={{
                                                    fontSize: 20,
                                                    color: colors.white,
                                                }}
                                            >
                                                {phone.phone}
                                            </Typography>
                                        ))}
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        pb: 8,
                                        width: "100%",
                                        display: "flex",
                                        borderBottom: `2px solid ${colors.secondary}`,
                                    }}
                                >
                                    <Box>
                                        <LocationOnOutlined
                                            sx={{
                                                fontSize: { md: 60, xs: 40 },
                                                color: colors.white,
                                                mr: 2,
                                            }}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: 20,
                                                color: colors.white,
                                            }}
                                        >
                                            P.o.Box 2740, Dar es Salaam -
                                            Tanzania Plot No 2118/98, Apartment
                                            No 002, Rajani Building, Indira
                                            Gandhi Street
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                    }}
                                >
                                    <Box>
                                        <Box>
                                            <Grid
                                                container
                                                spacing={2}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Grid item sm={12} xs={12}>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: "bold",
                                                            color: colors.white,
                                                        }}
                                                    >
                                                        @zanaboralimited
                                                    </Typography>
                                                </Grid>
                                                {socialLinks?.map((link) => (
                                                    <Grid
                                                        item
                                                        sm={2}
                                                        xs={2}
                                                        key={link.id}
                                                    >
                                                        <SocialLinkWidget
                                                            link={link}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Box
                                sx={{
                                    height: { md: 800, xs: "100%" },
                                    mt: -10,
                                    mb: 10,
                                    display: " flex",
                                    alignItems: "center",
                                }}
                            >
                                <Box>
                                    <Formik
                                        initialValues={{
                                            name: "",
                                            email: "",
                                            message: "",
                                        }}
                                    >
                                        {(formik) => (
                                            <Form>
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    padding={{ md: 5, xs: 2 }}
                                                >
                                                    <Grid item sm={12} xs={12}>
                                                        <Box
                                                            sx={{
                                                                boxShadow: `2px 2px 5px 0px silver`,
                                                            }}
                                                        >
                                                            <CustomTextField
                                                                name={"name"}
                                                                type={"text"}
                                                                label={
                                                                    language ===
                                                                    "swahili"
                                                                        ? "Jina Lako"
                                                                        : "name"
                                                                }
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item sm={12} xs={12}>
                                                        <Box
                                                            sx={{
                                                                boxShadow: `2px 2px 5px 0px silver`,
                                                            }}
                                                        >
                                                            <CustomTextField
                                                                name={"email"}
                                                                type={"email"}
                                                                label={
                                                                    language ===
                                                                    "swahili"
                                                                        ? "Barua Pepe Yako"
                                                                        : "email"
                                                                }
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item sm={12} xs={12}>
                                                        {/* <Typography
                                                            sx={{
                                                                mt: 3,
                                                                color: colors.primary,
                                                            }}
                                                        >
                                                            {language ===
                                                            "swahili"
                                                                ? "Tuandikie dokezo, na tutafanya onyesho bora zaidi kwa biashara yako"
                                                                : "Write a note for us, and we will make the best demo for your bussiness"}
                                                        </Typography> */}
                                                        <Box
                                                            sx={{
                                                                boxShadow: `2px 2px 5px 0px silver`,
                                                            }}
                                                        >
                                                            <CustomMultlineTextField
                                                                name={"Message"}
                                                                type={"text"}
                                                                label={
                                                                    language ===
                                                                    "swahili"
                                                                        ? "Ujumbe"
                                                                        : "Message"
                                                                }
                                                                rows={4}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item sm={12} xs={12}>
                                                        <CustomSubmitButton
                                                            btnColor={
                                                                "secondary"
                                                            }
                                                            sx={{
                                                                color: colors.primary,
                                                            }}
                                                        >
                                                            {language ===
                                                            "swahili"
                                                                ? "Tuma ujumbe wako"
                                                                : "Send your message"}
                                                        </CustomSubmitButton>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={10} xs={12}>
                            <Box sx={{ height: 400, mb: 10 }}>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.597500024918!2d39.284234999999995!3d-6.8187098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c4bcaa0dfd94d%3A0x127ecfed7bceea11!2sZana%20Bora%20Limited!5e0!3m2!1sen!2stz!4v1713821677776!5m2!1sen!2stz"
                                    width="100%"
                                    height="450"
                                    title="Location"
                                    frameborder="0"
                                    style={{ border: 0 }}
                                    allowfullscreen=""
                                    aria-hidden="false"
                                    tabindex="0"
                                ></iframe>
                            </Box>
                        </Grid>
                    </Grid>
                </SpacingContent>
            </Box>
        </>
    );
};

export default ContactUs;
