import { createSlice } from '@reduxjs/toolkit';

// => initialState
const initialState = {
    darkMode: false,
    // language: 'swahili',
    language: 'english',
};
export const systemConfigSlice = createSlice({
    name: 'systemConfigSlice',
    initialState,
    reducers: {
        setDarkMode: (state, action) => {
            state.darkMode = action.payload;
        },
        setLanguage: (state, action) => {
            state.language = action.payload;
        },
    },
});

export const { setDarkMode, setLanguage } = systemConfigSlice.actions;
export default systemConfigSlice.reducer;
