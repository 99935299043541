import React from "react";
import { useSelector } from "react-redux";
import { categoriesSelector } from "../states/features/selectors";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { colors } from "../hooks/useColor";
import { Image } from "@mui/icons-material";

const Categories = ({ closeDialog, menuToggle }) => {
    const { categories } = useSelector(categoriesSelector);

    return (
        <>
            <Box sx={{ width: "100%" }}>
                {categories.map((category) => (
                    <NavLink
                        key={category.id}
                        to={`/categories/${category.name}/${category.id}`}
                        onClick={() => {
                            closeDialog && closeDialog();
                            menuToggle && menuToggle();
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                mb: 0.5,
                                borderBottom: `1px dotted ${colors.secondary}`,
                            }}
                        >
                            <Grid container>
                                <Grid item sm={3} xs={3}>
                                    <Box
                                        sx={{
                                            height: 60,
                                            width: "100%",
                                        }}
                                    >
                                        <Avatar
                                            src={category.image}
                                            variant="square"
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                img: {
                                                    objectFit: "cover",
                                                },
                                            }}
                                        >
                                            <Image
                                                sx={{
                                                    width: "100%",
                                                    height: "100%",
                                                    img: {
                                                        objectFit: "cover",
                                                    },
                                                }}
                                            />
                                        </Avatar>
                                    </Box>
                                </Grid>
                                <Grid item sm={9} xs={9}>
                                    <Box
                                        sx={{
                                            height: 60,
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            p: 2,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: "bold",
                                                color: colors.bgColor5,
                                            }}
                                        >
                                            {category.name}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </NavLink>
                ))}
            </Box>
        </>
    );
};

export default Categories;
