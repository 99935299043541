import React, { useRef } from 'react';
import { Swiper } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import next from '../../assets/media/images/next.png';
import prev from '../../assets/media/images/prev.png';

// Import Swiper styles
import 'swiper/css';

import { Avatar, Box, Button, Grid } from '@mui/material';

const BannerSwiper = ({ conHeight, bgColor, content, children }) => {
    const sliderRef = useRef();
    return (
        <Box
            sx={{
                width: { md: '100%', xs: '100%' },
                position: 'relative',
                height: conHeight ? conHeight : 500,
                bgcolor: bgColor ? bgColor : 'none',
            }}
        >
            <Box
                sx={{
                    // position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Grid container justifyContent={'center'}>
                    <Grid item sm={12} xs={12}>
                        <Swiper
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            loop={true}
                            modules={[Autoplay, Pagination, Navigation]}
                            pagination={{
                                el: `.${content}`,
                                type: 'custom',
                                renderCustom: function (
                                    swiper,
                                    current,
                                    total,
                                ) {
                                    return current + '/' + total;
                                },
                            }}
                            // onSlideChange={() => console.log('slide change')}
                            onSwiper={(it) => (sliderRef.current = it)}
                            breakpoints={{
                                // when window width is >= 320px
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 24,
                                },
                                // when window width is >= 480px
                                480: {
                                    slidesPerView: 1,
                                    spaceBetween: 24,
                                },
                                // when window width is >= 640px
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 24,
                                },
                            }}
                        >
                            {children}
                        </Swiper>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        zIndex: 999,
                        mt: { md: '0%', xs: '100%' },
                    }}
                >
                    <Button
                        size="small"
                        color="secondary"
                        onClick={() => sliderRef.current?.slidePrev()}
                        sx={{ position: 'absolute', left: { md: 20, xs: 0 } }}
                    >
                        <Avatar src={prev} sx={{ height: 80, width: 80 }} />
                    </Button>
                    <Button
                        size="small"
                        color="secondary"
                        onClick={() => sliderRef.current?.slideNext()}
                        sx={{ position: 'absolute', right: { md: 20, xs: 0 } }}
                    >
                        <Avatar src={next} sx={{ height: 80, width: 80 }} />
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default BannerSwiper;
