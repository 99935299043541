import { configureStore } from "@reduxjs/toolkit";
import { RTKmiddleware } from "./RTKmiddlewares";

// => SLICE reduces
import systemConfigReducer from "../features/systemConfig/systemConfigSlice";
import bannerReducer from "../features/banner/bannerSlice";
import productReducer from "../features/products/productSlice";
import teamReducer from "../features/team/teamSlice";
import categoriesReducer from "../features/categories/categoriesSlice";

// => RTK reducers

export const store = configureStore({
    reducer: {
        systemConfigReducer,
        bannerReducer,
        productReducer,
        teamReducer,
        categoriesReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(RTKmiddleware),
});
