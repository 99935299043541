import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../hooks/useColor";

const TeamWidget = ({ team }) => {
    return (
        <>
            <Box
                sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor5})`,
                        height: { md: 250, sm: 250, xs: 150 },
                        width: { md: 250, sm: 250, xs: 150 },
                        borderRadius: 50,
                        boxShadow: `0 0 5px ${colors.secondary}`,
                    }}
                >
                    <Avatar
                        src={team.passport}
                        sx={{
                            height: { md: 250, sm: 250, xs: 150 },
                            width: { md: 250, sm: 250, xs: 150 },
                            objectFit: "cover",
                            p: 1.5,
                            img: {
                                borderRadius: 50,
                            },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        mt: 1,
                        px: 2,
                        fontWeight: "bold",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: { md: 25, sm: 25, xs: 16 },
                            color: colors.primary,
                            fontWeight: "bold",
                            textAlign: "center",
                            textTransform: "capitalize",
                        }}
                    >
                        {team.name}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: { md: 16, sm: 16, xs: 13 },
                            color: colors.primary,
                            textAlign: "center",
                        }}
                    >
                        {team.occupation}
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default TeamWidget;
