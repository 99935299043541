import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { colors } from "../hooks/useColor";
import { useSelector } from "react-redux";
import {
    productSelector,
    systemConfigSelector,
} from "../states/features/selectors";
import ProductWidget from "./widgets/ProductWidget";
import { take } from "lodash";

// ######  Products ######
import product1 from "../assets/media/images/products/home/product1.png";
import product2 from "../assets/media/images/products/home/product2.png";
import product3 from "../assets/media/images/products/home/product3.png";
import product4 from "../assets/media/images/products/home/product4.png";
import product5 from "../assets/media/images/products/home/product5.png";
import product6 from "../assets/media/images/products/home/product6.png";
import product7 from "../assets/media/images/products/home/product7.png";
import product8 from "../assets/media/images/products/home/product8.png";
import product9 from "../assets/media/images/products/home/product9.png";
import product10 from "../assets/media/images/products/home/product10.png";
import product11 from "../assets/media/images/products/home/product11.png";

const OurProducts = () => {
    // ############ Redux state ##############
    const { language } = useSelector(systemConfigSelector);

    const homeProducts = [
        {
            id: 1,
            title: "KUBOTA POWER TILLER ZT 140 PLUS",
            image: product1,
        },
        {
            id: 2,
            title: "VST VIRAAJ XP 9054 DI(HP 50 & HP 45)",
            image: product2,
        },
        {
            id: 3,
            title: "MASSEY FERGUSON TRACTOR",
            image: product3,
        },
        {
            id: 4,
            title: "VST POWER REAPER (VST SHAKTI 5PR)",
            image: product4,
        },
        {
            id: 5,
            title: "DISC PLOUGH (4 DISCS)-",
            image: product5,
        },
        {
            id: 6,
            title: "DONGFENG TRACTOR DF-504A (50HP, 4WD",
            image: product6,
        },
        {
            id: 7,
            title: "MAIZE SHELLER",
            image: product7,
        },
        {
            id: 8,
            title: "MULTI-CROP PLANTER",
            image: product8,
        },
        {
            id: 9,
            title: "RICE THRESHER B4",
            image: product9,
        },
        {
            id: 10,
            title: "SLASHER",
            image: product10,
        },
        {
            id: 11,
            title: "VIDHATA SILAGE MACHINE",
            image: product11,
        },
    ];

    return (
        <>
            <Box>
                <Box sx={{ p: 2, bgcolor: colors.primary }}>
                    <Typography
                        sx={{
                            textAlign: "center",
                            textTransform: "uppercase",
                            color: colors.white,
                            fontWeight: "bold",
                            fontSize: { md: 35, xs: 25 },
                        }}
                    >
                        {language === "swahili"
                            ? "Bidhaa zetu"
                            : "Our products"}
                    </Typography>
                </Box>
                <Box>
                    <Grid container spacing={2}>
                        {take(homeProducts, 6).map((product) => (
                            <Grid item sm={4} xs={12} key={product.id}>
                                <ProductWidget product={product} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default OurProducts;
