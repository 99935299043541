import { createSlice } from "@reduxjs/toolkit";
import {
    teamDummyDataEng,
    teamDummyDataSw,
} from "../../dummyData/teamDummyData";

// => initialState
const initialState = {
    teamSw: teamDummyDataSw,
    teamEng: teamDummyDataEng,
};
export const teamSlice = createSlice({
    name: "teamSlice",
    initialState,
    reducers: {
        setTeam: (state, action) => {
            state.team = action.payload;
        },
    },
});

export const { setTeam } = teamSlice.actions;
export default teamSlice.reducer;
