import { Avatar, Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { systemConfigSelector } from "../../states/features/selectors";
import { setLanguage } from "../../states/features/systemConfig/systemConfigSlice";
import swLun from "../../assets/media/images/sw.png";
import engLun from "../../assets/media/images/eng.png";
import { colors } from "../../hooks/useColor";

const LanguageChangeBtn = () => {
    // ########### Const ##################
    const dispatch = useDispatch();

    // ############ Redux state ##############
    const { language } = useSelector(systemConfigSelector);

    return (
        <>
            {language === "swahili" ? (
                <>
                    <Button
                        variant="contained"
                        onClick={() => {
                            dispatch(setLanguage("english"));
                        }}
                        sx={{
                            p: 0,
                            borderRadius: 5,
                            overflow: "hidden",
                            bgcolor: `${colors.primary} !important`,
                        }}
                    >
                        <Avatar
                            src={engLun}
                            variant="square"
                            sx={{
                                width: 150,
                                img: { objectFit: "contain" },
                            }}
                        />
                    </Button>
                </>
            ) : (
                <>
                    <Button
                        variant="contained"
                        onClick={() => {
                            dispatch(setLanguage("swahili"));
                        }}
                        sx={{
                            p: 0,
                            borderRadius: 5,
                            overflow: "hidden",
                            bgcolor: `${colors.primary} !important`,
                        }}
                    >
                        <Avatar
                            src={swLun}
                            variant="square"
                            sx={{
                                width: 150,
                                img: { objectFit: "contain" },
                            }}
                        />
                    </Button>
                </>
            )}
        </>
    );
};

export default LanguageChangeBtn;
