import React from "react";
import { Route, Routes } from "react-router-dom";
import Index from "../pages/Index";
import AppLayout from "../pages/layouts/AppLayout";
import ContactUs from "../pages/ContactUs/ContactUs";
import OurAgents from "../pages/OurAgents/OurAgents";
import Gallaries from "../pages/Gallary/Gallaries";
import WhatWeDo from "../pages/WhatWeDo/WhatWeDo";
import AboutUs from "../pages/AboutUs/AboutUs";
import CategoryDetails from "../pages/categories/CategoryDetails";
// import UnderMaintanance from '../pages/errors/UnderMaintanance';

// ?############## GUEST ELEMENTS ###########
// const UnderMaintananceElement = () => <UnderMaintanance />;
const IndexElement = () => (
    <AppLayout>
        <Index />
    </AppLayout>
);
const AboutUsElement = () => (
    <AppLayout>
        <AboutUs />
    </AppLayout>
);
const WhatWeDoElement = () => (
    <AppLayout>
        <WhatWeDo />
    </AppLayout>
);
const GallariesElement = () => (
    <AppLayout>
        <Gallaries />
    </AppLayout>
);
const OurAgentsElement = () => (
    <AppLayout>
        <OurAgents />
    </AppLayout>
);
const ContactUsElement = () => (
    <AppLayout>
        <ContactUs />
    </AppLayout>
);
const CategoryDetailsElement = () => (
    <AppLayout>
        <CategoryDetails />
    </AppLayout>
);

// !############## MAIN FUNC ###########
const App = () => {
    return (
        <React.Fragment>
            <Routes>
                {/* <Route path="/" element={<UnderMaintananceElement />} /> */}
                <Route path="/" element={<IndexElement />} />
                <Route path="/about-us" element={<AboutUsElement />} />
                <Route path="/what-we-do" element={<WhatWeDoElement />} />
                <Route path="/gallaries" element={<GallariesElement />} />
                <Route path="/our-dealers" element={<OurAgentsElement />} />
                <Route path="/contact-us" element={<ContactUsElement />} />
                <Route
                    path="/categories/:category_name/:category_id"
                    element={<CategoryDetailsElement />}
                />
            </Routes>
        </React.Fragment>
    );
};

export default App;
