import React from "react";
import PageBanner from "../../components/PageBanner";
import bgImage from "../../assets/media/images/general/does2.jpg";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { colors } from "../../hooks/useColor";
import SpacingContent from "../../components/SpacingContent";
// import does1 from '../../assets/media/images/zana/zana21.png';
// import does2 from '../../assets/media/images/zana/zana22.png';
import does3 from "../../assets/media/images/gallary/g16.png";
import does4 from "../../assets/media/images/gallary/g4.png";
import does5 from "../../assets/media/images/gallary/g12.png";
import { systemConfigSelector } from "../../states/features/selectors";
import { useSelector } from "react-redux";
import OurProducts from "../../components/OurProducts";
import does1 from "../../assets/media/images/general/does1.jpg";
import product1 from "../../assets/media/images/general/product1.jpg";

const WhatWeDo = () => {
    // ############ Redux state ##############
    const { language } = useSelector(systemConfigSelector);

    const ourDoes = [
        {
            id: 1,
            contentOrder: { md: 1, xs: 2 },
            imageOrder: { md: 2, xs: 1 },
            bgColor: colors.secondary,
            image: product1,
            fit: "contain",
            title:
                language === "swahili"
                    ? "Mashine za kilimo"
                    : "Agricultural machinery",
            description:
                language === "swahili"
                    ? "Zana Bora imeanzisha timu kimkakati ili kutoa huduma bora na za haraka kwa wateja wetu waheshimiwa kote Tanzania. Kampuni yetu ina timu ndogo ya kufanya kazi kwa bidii na roho ya timu iliyoshikamana ili kufikia malengo yetu."
                    : "Zana Bora is strategically set up team in order to provide quality and quick services to our esteemed customers throughout Tanzania. Our company has a small hard working team with cohesive team spirit to achieve our targets.",
        },
        {
            id: 2,
            contentOrder: { md: 2, xs: 2 },
            imageOrder: { md: 1, xs: 1 },
            bgColor: colors.white,
            image: does1,
            fit: "contain",
            title: language === "swahili" ? "Vipuri" : "Spare Parts",
            description:
                language === "swahili"
                    ? "Katika ulimwengu unaobadilika wa kilimo, Kama Zana Bora Limited kuhakikisha utendakazi mzuri wa mashine ni muhimu. Hapo ndipo kuuza vipuri vya mashine za kilimo kunapohusika. Fikiria hili: mkulima akiwa katikati ya msimu muhimu wa upanzi, na kifaa muhimu kikivunjika. Hapo ndipo biashara yako inapoingia, kutoa njia ya kuokoa maisha kwa kutoa vipuri vya ubora wa juu, vinavyotegemeka"
                    : "In the dynamic world of agriculture, As Zana Bora Limited ensuring the smooth operation of machinery is crucial. That's where reselling agricultural machinery spare parts comes into play. Picture this: a farmer in the midst of a crucial planting season, and a vital piece of equipment breaks down. That's where your venture steps in, offering a lifeline by providing high-quality, reliable spare parts.",
        },
        {
            id: 3,
            contentOrder: { md: 1, xs: 2 },
            imageOrder: { md: 2, xs: 1 },
            bgColor: colors.secondary,
            image: does3,
            title:
                language === "swahili"
                    ? "Huduma baada ya mauzo"
                    : "After sale service",
            description:
                language === "swahili"
                    ? "Kwa hivyo, umewekeza katika mashine za kisasa za kilimo - lakini kinachotokea baada ya ununuzi ni muhimu vile vile. Karibu katika ulimwengu wa huduma za mashine za kilimo baada ya mauzo, ambapo ahadi yetu haiishii kwenye shughuli; ni mwanzo tu wa ushirikiano wa kudumu."
                    : "So, you've invested in cutting-edge agricultural machinery – but what happens after the purchase is just as crucial. Welcome to the world of agricultural machinery after-sale services, where our commitment doesn't end with the transaction; it's just the beginning of a lasting partnership.",
        },
        {
            id: 4,
            contentOrder: { md: 2, xs: 2 },
            imageOrder: { md: 2, xs: 1 },
            bgColor: colors.white,
            image: does4,
            title:
                language === "swahili" ? "Mafunzo ya shamba" : "Field training",
            description:
                language === "swahili"
                    ? "Ingia shambani na Mafunzo yetu ya Uwanda wa Mashine za Kilimo - ambapo maarifa ya kinadharia yanakidhi ukweli wa vitendo wa kilimo. Katika uzoefu huu wa kina, tunaenda zaidi ya miongozo na madarasa, kuhakikisha kwamba unaelewa sio tu jinsi ya kuendesha mashine yako lakini kuisimamia kati ya mazao."
                    : "Step into the field with our Agricultural Machinery Field Training – where theoretical knowledge meets the practical reality of farming. In this immersive experience, we go beyond the manuals and classrooms, ensuring that you not only understand how to operate your machinery but master it amidst the crops.",
        },
        {
            id: 5,
            contentOrder: { md: 2, xs: 2 },
            imageOrder: { md: 1, xs: 1 },
            bgColor: colors.secondary,
            image: does5,
            title: language === "swahili" ? "Mikopo" : "Loans",
            description:
                language === "swahili"
                    ? "Unakopesheka?, kama jibu ni ndiyo basi tutakuunganisha na taasisi mbalimbali za fedha kama CRDB, NMB, EQUITY, EFTA, MFUKO WA MAPATO YA TAIFA, PASS, TADB."
                    : "Are you interested in a loan, we help you connect with institutions like CRDB BANK, NMB BANK, EQUITY BANK, EFTA, AZANIA BANK, PASS LEASING, and MFUKO WA  PEMBEJEO WA TAIFA",
        },
    ];
    return (
        <>
            <PageBanner bgImage={bgImage}></PageBanner>
            <Box>
                <SpacingContent>
                    {ourDoes.map((does) => (
                        <Box key={does.id}>
                            <Grid container>
                                <Grid
                                    item
                                    sm={6}
                                    xs={12}
                                    order={does.contentOrder}
                                >
                                    <Box
                                        sx={{
                                            p: { md: 10, xs: 3 },
                                            bgcolor: does.bgColor,
                                            height: "100%",
                                            position: "relative",
                                            display: "flex",
                                            alignItems: "center",
                                            ":after": {
                                                content: "''",
                                                width: 100,
                                                height: 100,
                                                position: "absolute",
                                                right: -20,
                                                transform: `rotate(45deg)`,
                                                display: {
                                                    md: "block",
                                                    xs: "none",
                                                },
                                                bgcolor:
                                                    does.contentOrder === 1
                                                        ? colors.secondary
                                                        : "",
                                            },
                                        }}
                                    >
                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        md: 45,
                                                        xs: 35,
                                                    },
                                                    fontWeight: "bold",
                                                    color: colors.primary,
                                                }}
                                            >
                                                {does.title}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: 18,
                                                    color: colors.primary,
                                                    lineHeight: 1.5,
                                                }}
                                            >
                                                {does.description}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    sm={6}
                                    xs={12}
                                    order={does.imageOrder}
                                >
                                    <Box sx={{ height: { md: 500, xs: 300 } }}>
                                        <Avatar
                                            variant="square"
                                            src={does.image}
                                            sx={{
                                                height: `100%`,
                                                width: "100%",
                                                objectFit: does.fit,
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    ))}
                </SpacingContent>
            </Box>
        </>
    );
};

export default WhatWeDo;
