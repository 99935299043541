import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../hooks/useColor";

const MissionVisionValueWidget = ({ item }) => {
    return (
        <>
            <Box
                sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    borderRight: item.border
                        ? `4px solid ${colors.secondary}`
                        : "none",
                    borderLeft: item.border
                        ? `4px solid ${colors.secondary}`
                        : "none",
                }}
            >
                <Box
                    sx={{
                        // background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor5})`,
                        height: 150,
                        width: 150,
                        borderRadius: 50,
                    }}
                >
                    <Avatar
                        src={item.titleImg}
                        sx={{
                            height: 150,
                            width: 150,
                            objectFit: "cover",
                            img: {
                                borderRadius: 50,
                            },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        mt: 1,
                        px: 2,
                        fontWeight: "bold",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 35,
                            color: colors.primary,
                            fontWeight: "bold",
                            textAlign: "center",
                            textTransform: "capitalize",
                        }}
                    >
                        {item.title}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 16,
                            color: colors.primary,
                            textAlign: "center",
                        }}
                    >
                        {item.description}
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default MissionVisionValueWidget;
