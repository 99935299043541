import {
    Box,
    ClickAwayListener,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { colors } from "../hooks/useColor";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const NavbarLinkTwo = ({ link, menuToggle, handleClose }) => {
    const navigate = useNavigate();
    const [subLinkDisplay, setSubLinkDisplay] = useState("none");
    const handleSubLinkToggle = () => {
        subLinkDisplay === "none"
            ? setSubLinkDisplay("block")
            : setSubLinkDisplay("none");
    };
    return (
        <>
            <ClickAwayListener onClickAway={handleClose}>
                <NavLink onClick={menuToggle} style={{ width: "100%" }}>
                    {({ isActive }) => (
                        <ListItem className="item">
                            <ListItemButton
                                className="btn"
                                sx={{
                                    color: isActive
                                        ? colors.secondary
                                        : colors.textColor,
                                    // borderBottom: isActive
                                    //     ? `2px solid ${colors.secondary}`
                                    //     : `2px solid ${colors.primary}`,
                                }}
                                onClick={() => {
                                    handleSubLinkToggle();
                                }}
                            >
                                <Typography
                                    noWrap
                                    className="title"
                                    sx={{
                                        color: "#fff",
                                        fontSize: 12,
                                        textTransform: "uppercase",
                                    }}
                                >
                                    {link.title}
                                </Typography>
                                {link.subLinks && (
                                    <ListItemIcon
                                        sx={{
                                            position: "absolute",
                                            left: "100%",
                                        }}
                                    >
                                        {subLinkDisplay === "none" ? (
                                            <ExpandMore color="secondary" />
                                        ) : (
                                            <ExpandLess color="secondary" />
                                        )}
                                    </ListItemIcon>
                                )}
                            </ListItemButton>
                            {link.subLinks && (
                                <Box
                                    sx={{
                                        bgcolor: colors.primary,
                                        position: "absolute",
                                        top: "100%",
                                        left: 0,
                                        textAlign: "left",
                                        display: subLinkDisplay,
                                    }}
                                >
                                    {link.subLinks?.map((subLink) => (
                                        <ListItemButton
                                            className="btn"
                                            key={subLink.id}
                                            sx={{
                                                color: isActive
                                                    ? colors.secondary
                                                    : colors.textColor,
                                                // borderBottom: isActive
                                                //     ? `2px solid ${colors.secondary}`
                                                //     : `2px solid ${colors.primary}`,
                                                textAlign: "left !important",
                                            }}
                                            onClick={() => {
                                                navigate(subLink.url);
                                                handleSubLinkToggle();
                                            }}
                                        >
                                            <Typography
                                                noWrap
                                                className="title"
                                                sx={{
                                                    color: "#fff",
                                                    fontSize: 13,
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                {subLink.title}
                                            </Typography>
                                        </ListItemButton>
                                    ))}
                                </Box>
                            )}
                        </ListItem>
                    )}
                </NavLink>
            </ClickAwayListener>
        </>
    );
};

export default NavbarLinkTwo;
