// => Tiller
import tiller1 from "../../assets/media/images/products/categories/1_tiller/1.png";
import tiller2 from "../../assets/media/images/products/categories/1_tiller/2.png";
import tiller3 from "../../assets/media/images/products/categories/1_tiller/3.png";
// => Tractors
import tractor1 from "../../assets/media/images/products/categories/2_tractors/1.png";
import tractor2 from "../../assets/media/images/products/categories/2_tractors/2.png";
import tractor3 from "../../assets/media/images/products/categories/2_tractors/3.png";
// => Discs
import disc1 from "../../assets/media/images/products/categories/3_discs/1.png";
import disc2 from "../../assets/media/images/products/categories/3_discs/2.png";
import disc3 from "../../assets/media/images/products/categories/3_discs/3.png";
// => Threshers
import threshers1 from "../../assets/media/images/products/categories/4_harvesters/1.jpg";
import threshers2 from "../../assets/media/images/products/categories/4_harvesters/2.png";
import threshers3 from "../../assets/media/images/products/categories/4_harvesters/3.png";
// => Shellers
import shellers1 from "../../assets/media/images/products/categories/5_threshsers_and_shellers/1.png";
import shellers2 from "../../assets/media/images/products/categories/5_threshsers_and_shellers/2.png";
import shellers3 from "../../assets/media/images/products/categories/5_threshsers_and_shellers/3.png";
// => Other
import other1 from "../../assets/media/images/products/categories/6_other_implement/1.png";
import other2 from "../../assets/media/images/products/categories/6_other_implement/2.png";
import other3 from "../../assets/media/images/products/categories/6_other_implement/3.png";
import other4 from "../../assets/media/images/products/categories/6_other_implement/4.png";
import other5 from "../../assets/media/images/products/categories/6_other_implement/5.png";
import other6 from "../../assets/media/images/products/categories/6_other_implement/6.png";
import other7 from "../../assets/media/images/products/categories/6_other_implement/7.png";
import other8 from "../../assets/media/images/products/categories/6_other_implement/8.png";
import other9 from "../../assets/media/images/products/categories/6_other_implement/9.png";

export const categoriesDummyData = [
    {
        id: 1,
        name: "Tiller",
        image: tiller1,
        products: [
            {
                id: 1,
                title: "KUBOTA POWER TILLER PEM ZT 140 PLUS",
                image: tiller1,
            },
            {
                id: 2,
                title: "KUBOTA POWER TILLER ZT 140 PLUS",
                image: tiller2,
            },
            {
                id: 3,
                title: "VST POWER TILLER (135 DI ULTRA) - HP 13.5 & HP 16.5",
                image: tiller3,
            },
        ],
    },
    {
        id: 2,
        name: "Tractors",
        image: tractor1,
        products: [
            {
                id: 1,
                title: "DONGFENG TRACTOR DF-504A (50HP, 4WD",
                image: tractor1,
            },
            {
                id: 2,
                title: "MASSEY FERGUSON TRACTOR",
                image: tractor2,
            },
            {
                id: 3,
                title: "VST VIRAAJ XP 9054 DI(HP 50 & HP 45)",
                image: tractor3,
            },
        ],
    },
    {
        id: 3,
        name: "Discs",
        image: disc1,
        products: [
            {
                id: 1,
                title: "3 DISCS PLOUGH",
                image: disc1,
            },
            {
                id: 2,
                title: "DISC HARROW",
                image: disc2,
            },
            {
                id: 3,
                title: "DISC PLOUGH (4 DISCS)",
                image: disc3,
            },
        ],
    },
    {
        id: 4,
        name: "Threshers",
        image: threshers1,
        products: [
            {
                id: 1,
                title: "TRAILER 1.5TONS and 5 tons",
                image: threshers1,
            },
            {
                id: 2,
                title: "TRAILER-5-tons",
                image: threshers2,
            },
            {
                id: 3,
                title: "VST POWER REAPER (VST SHAKTI 5PR)",
                image: threshers3,
            },
        ],
    },
    {
        id: 5,
        name: "Shellers",
        image: shellers1,
        products: [
            {
                id: 1,
                title: "MAIZE SHELLER",
                image: shellers1,
            },
            {
                id: 2,
                title: "RICE THRESHER B4",
                image: shellers2,
            },
            {
                id: 3,
                title: "VIETNAM PADDY THRESHER AT1200",
                image: shellers3,
            },
        ],
    },
    {
        id: 6,
        name: "Other impliments",
        image: other1,
        products: [
            {
                id: 1,
                title: "BOOM SPRAYER",
                image: other1,
            },
            {
                id: 2,
                title: "CULTIVATOR",
                image: other2,
            },
            {
                id: 3,
                title: "IRISH POTATOE PLANTER",
                image: other3,
            },
            {
                id: 4,
                title: "LAWN MOWER",
                image: other4,
            },
            {
                id: 5,
                title: "MULTI-CROP PLANTER",
                image: other5,
            },
            {
                id: 6,
                title: "MULTI-CROP PLANTER_1",
                image: other6,
            },
            {
                id: 7,
                title: "ROTAVATOR",
                image: other7,
            },
            {
                id: 8,
                title: "SLASHER",
                image: other8,
            },
            {
                id: 9,
                title: "VIDHATA SILAGE MACHINE",
                image: other9,
            },
        ],
    },
];
