// => Auth selector
export const authSelector = (state) => state.authReducer;

// => Banner selector
export const bannerSelector = (state) => state.bannerReducer;

// => Product selector
export const productSelector = (state) => state.productReducer;

// => Team selector
export const teamSelector = (state) => state.teamReducer;

// => Categories selector
export const categoriesSelector = (state) => state.categoriesReducer;

// => systemConfig selector
export const systemConfigSelector = (state) => state.systemConfigReducer;
