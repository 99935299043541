import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { Avatar, Box, IconButton, List } from "@mui/material";
import CustomListItem from "./MUIcustoms/CustomListItem";
import { colors } from "../hooks/useColor";
import { navbarLinks1, navbarLinks2, productLink } from "../utils/navbarLinks";
import { Close, Segment } from "@mui/icons-material";
import logo from "../assets/media/images/logo.png";
import { Link } from "react-router-dom";
import CustomListItemTwo from "./MUIcustoms/CustomListItemTwo";

// ############### CONSTANTS #################
const drawerWidth = 250;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});
const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

// ############### STYLED DESKTOP DRAWER #################
const DrawerDesktop = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    "& .MuiPaper-root": {
        backgroundColor: colors.primary,
    },
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

// ################# DrawerContent ################
const DrawerContent = styled(Box)(({ theme }) => ({
    bgcolor: colors.bgColor3,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 15,
    paddingRight: 15,
    transition: ".3s all ease-in-out",
    "& .list-title": {
        fontWeight: "bold",
        fontSize: 12,
        padding: 5,
        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
    },
    "& .list-icon": {
        fontSize: 18,
        color: colors.secondary,
    },
}));

//! #################### MAIN FUNC ######################
const SideBar = ({ handleDrawerToggle, mobileOpen }) => {
    // ############ CONST ###################
    const [open] = React.useState(false);

    // => For content to be below app bar
    const AppBarHeight = styled("div")(({ theme }) => ({
        ...theme.mixins.toolbar,
    }));
    const { window } = "props";
    const container =
        window !== undefined ? () => window().document.body : undefined;

    // ############## Links ##################
    const links1 = navbarLinks1;
    const links2 = navbarLinks2;

    // ##################### Drawer #################
    const drawer = (
        <React.Fragment>
            <DrawerContent>
                <Box>
                    <List sx={{ p: 0 }}>
                        {links1?.map((item) => (
                            <CustomListItem
                                key={item.id}
                                text={item.title}
                                icon={item.icon}
                                url={item.url}
                                subLink={item.subLinks}
                            />
                        ))}
                        <CustomListItemTwo
                            key={productLink.id}
                            text={productLink.title}
                            icon={productLink.icon}
                            url={productLink.url}
                            menuToggle={handleDrawerToggle}
                        />
                        {links2?.map((item) => (
                            <CustomListItem
                                key={item.id}
                                text={item.title}
                                icon={item.icon}
                                url={item.url}
                                subLink={item.subLinks}
                            />
                        ))}
                    </List>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            bgcolor: colors.primary,
                            mt: 5,
                        }}
                    >
                        <Link to={"/"}>
                            <Avatar
                                src={logo}
                                variant="square"
                                sx={{
                                    height: { md: 65, sm: 65, xs: 55 },
                                    width: "100%",
                                    img: {
                                        objectFit: "cover",
                                    },
                                }}
                            />
                        </Link>
                    </Box>
                </Box>
            </DrawerContent>
        </React.Fragment>
    );
    return (
        <>
            {/* ##################### mobile ################# */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: "100%", // Change the width of drawer in mobile
                        backgroundColor: colors.primary,
                    },
                }}
            >
                {/* ############ Mob Toggle ############ */}
                {mobileOpen ? (
                    <IconButton
                        size="small"
                        onClick={handleDrawerToggle}
                        color="inherit"
                        sx={{
                            display: {
                                xs: "block",
                                sm: "none",
                            },
                        }}
                    >
                        <Close sx={{ fontSize: 35, color: "#fff" }} />
                    </IconButton>
                ) : (
                    <IconButton
                        size="small"
                        onClick={handleDrawerToggle}
                        color="inherit"
                        sx={{
                            display: {
                                xs: "block",
                                sm: "none",
                            },
                        }}
                    >
                        <Segment sx={{ fontSize: 35, color: "#fff" }} />
                    </IconButton>
                )}
                <Toolbar />
                {drawer}
            </Drawer>

            {/* ##################### desktop ################ */}
            <DrawerDesktop
                variant="temporary"
                open={open}
                sx={{
                    display: { xs: "none", sm: "block" },
                }}
            >
                <AppBarHeight />
                <Divider />
                {drawer}
            </DrawerDesktop>
        </>
    );
};

export default SideBar;
