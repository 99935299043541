import React from "react";
import Section from "./Section";
import SpacingContent from "./SpacingContent";
// import p1 from '../assets/media/images/partiners/p1.png';
import p2 from "../assets/media/images/partiners/p2.png";
import p3 from "../assets/media/images/partiners/p3.png";
import p4 from "../assets/media/images/partiners/p4.png";
import p5 from "../assets/media/images/partiners/p5.png";
import p6 from "../assets/media/images/partiners/p6.png";
import p7 from "../assets/media/images/partiners/p7.png";
// import p8 from '../assets/media/images/partiners/p8.png';
import p9 from "../assets/media/images/partiners/p9.png";
import p10 from "../assets/media/images/partiners/p10.png";
import p11 from "../assets/media/images/partiners/p11.png";
import p12 from "../assets/media/images/partiners/p12.png";
import p13 from "../assets/media/images/partiners/p13.png";
import p14 from "../assets/media/images/partiners/p14.png";
import { Avatar, Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { systemConfigSelector } from "../states/features/selectors";

const OurPartiners = () => {
    // ############ Redux state ##############
    const { language } = useSelector(systemConfigSelector);

    const partiners = [
        {
            id: 1,
            name: "Kubota",
            logo: p7,
        },
        {
            id: 2,
            name: "massey",
            logo: p11,
        },
        {
            id: 3,
            name: "dong",
            logo: p14,
        },
        {
            id: 4,
            name: "tiller",
            logo: p13,
        },
        {
            id: 5,
            name: "visata",
            logo: p10,
        },
        {
            id: 6,
            name: "CRDB",
            logo: p3,
        },
        {
            id: 7,
            name: "NMB",
            logo: p6,
        },
        // {
        //     id: 8,
        //     name: 'NBC',
        //     logo: p8,
        // },
        // {
        //     id: 9,
        //     name: 'Azaniz',
        //     logo: p1,
        // },
        {
            id: 10,
            name: "Pass",
            logo: p9,
        },
        {
            id: 11,
            name: "Equity",
            logo: p5,
        },
        {
            id: 12,
            name: "efta",
            logo: p2,
        },
        {
            id: 13,
            name: "Tadb",
            logo: p12,
        },
        {
            id: 14,
            name: "mfuko",
            logo: p4,
        },
    ];
    return (
        <>
            <Box sx={{ mt: 15 }}>
                <Section
                    head={
                        language === "swahili"
                            ? "WASHIRIKA WETU KATIKA BIASHARA"
                            : "Our partiners in business"
                    }
                    pTop
                >
                    <SpacingContent>
                        <Grid
                            container
                            justifyContent={"center"}
                            sx={{ overflowX: "hidden" }}
                        >
                            {partiners.map((partiner) => (
                                <Grid item sm={2} xs={6} key={partiner.id}>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Avatar
                                            src={partiner.logo}
                                            variant="square"
                                            sx={{
                                                height: 100,
                                                width: 150,
                                                img: {
                                                    objectFit: "contain",
                                                },
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </SpacingContent>
                </Section>
            </Box>
        </>
    );
};

export default OurPartiners;
