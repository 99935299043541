import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useMediaQuery } from "@mui/material";
import g2 from "../../../assets/media/images/gallary/zana1.jpg";
import g3 from "../../../assets/media/images/gallary/g18.png";
import g4 from "../../../assets/media/images/gallary/g19.png";
import g5 from "../../../assets/media/images/gallary/g20.png";
import g6 from "../../../assets/media/images/gallary/g21.png";
import g7 from "../../../assets/media/images/gallary/g22.png";
import g8 from "../../../assets/media/images/gallary/g23.png";
import g9 from "../../../assets/media/images/gallary/g16.png";
import g10 from "../../../assets/media/images/gallary/g24.png";
import g11 from "../../../assets/media/images/gallary/g25.png";
import g12 from "../../../assets/media/images/gallary/g26.png";
import g13 from "../../../assets/media/images/gallary/g27.png";
import g14 from "../../../assets/media/images/gallary/g38.png";
import g15 from "../../../assets/media/images/gallary/g28.png";
import g16 from "../../../assets/media/images/gallary/g29.png";
import g17 from "../../../assets/media/images/gallary/g30.png";
import g18 from "../../../assets/media/images/gallary/g31.png";
import g19 from "../../../assets/media/images/gallary/g32.png";
import g20 from "../../../assets/media/images/gallary/g37.png";
import g21 from "../../../assets/media/images/gallary/g36.png";
import g22 from "../../../assets/media/images/gallary/g35.png";
import g23 from "../../../assets/media/images/gallary/g34.png";
import g24 from "../../../assets/media/images/gallary/g33.png";

export default function GallariesDisplay() {
    const deviceWidth = useMediaQuery("(min-width:600px)");
    return (
        <ImageList
            sx={{ width: "100%", height: "100%" }}
            cols={deviceWidth ? 3 : 1}
            gap={0}
        >
            {itemData.map((item) => (
                <ImageListItem
                    key={item.img}
                    sx={{
                        position: "relative",
                    }}
                >
                    <img
                        src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.title}
                        loading="lazy"
                        style={{ height: `300px` }}
                    />
                </ImageListItem>
            ))}
        </ImageList>
    );
}

const itemData = [
    {
        img: g2,
        title: "kubota power tiller",
    },
    {
        img: g3,
        title: "Vidhata Silage machine",
    },
    {
        img: g4,
        title: "VST power tiller",
    },
    {
        img: g5,
        title: "Vidhata maize shelller",
    },
    {
        img: g6,
        title: "Massey Ferguson Tractor",
    },
    {
        img: g7,
        title: "VST VIRAAJ Tractor Shakti 9054 DI",
    },
    {
        img: g8,
        title: "Trailer",
    },
    {
        img: g9,
        title: "Tractor",
    },
    {
        img: g10,
        title: "Multi crop planter",
    },
    {
        img: g11,
        title: "Products on stock",
    },
    {
        img: g12,
        title: "Our main store",
    },
    {
        img: g13,
        title: "Kubota Power tiller spares",
    },
    {
        img: g14,
        title: "Harvesting machine",
    },
    {
        img: g15,
        title: "National event",
    },
    {
        img: g16,
        title: "Stuff Seminar",
    },
    {
        img: g17,
        title: "Loan Success",
    },
    {
        img: g18,
        title: "Discs Plough",
    },
    {
        img: g19,
        title: "Seminar",
    },
    {
        img: g20,
        title: "Seminar",
    },
    {
        img: g21,
        title: "Seminar",
    },
    {
        img: g22,
        title: "Loan success",
    },
    {
        img: g23,
        title: "Our team on sminarr",
    },
    {
        img: g24,
        title: "Our product showcase",
    },
];
