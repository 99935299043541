import kajalJoban from "../../assets/media/images/team/kajalJoban.jpg";
import vjayJoban from "../../assets/media/images/team/vjayJoban.jpg";
import user from "../../assets/media/images/team/user.png";
import hassanAHassan from "../../assets/media/images/team/hassanAHassan.jpg";
import johariRajabu from "../../assets/media/images/team/johariRajabu.jpg";
import halima from "../../assets/media/images/team/halima.jpg";

export const teamDummyDataEng = [
    {
        id: 1,
        name: "Kajal Jobanputra",
        occupation: "General Director",
        passport: kajalJoban,
    },
    {
        id: 2,
        name: "Vijay Jobanputra",
        occupation: "Managing Director",
        passport: vjayJoban,
    },
    {
        id: 3,
        name: "Jay Jobanputra",
        occupation: "Business Development and HR officer",
        passport: user,
    },
    {
        id: 4,
        name: "Hassani Athumani Hassani",
        occupation: "General manager",
        passport: hassanAHassan,
    },
    {
        id: 5,
        name: "Johari Ally Rajabu",
        occupation: "Administrative & Marketing",
        passport: johariRajabu,
    },
    {
        id: 6,
        name: "Halima Yusuph Chitemo",
        occupation: "Administrative",
        passport: halima,
    },
];

export const teamDummyDataSw = [
    {
        id: 1,
        name: "Rojas Mbugi",
        occupation: "Sales Executive",
        passport: user,
    },
    {
        id: 2,
        name: "Rojas Mbugi",
        occupation: "Sales Executive",
        passport: user,
    },
    {
        id: 3,
        name: "Rojas Mbugi",
        occupation: "Sales Executive",
        passport: user,
    },
    {
        id: 4,
        name: "Rojas Mbugi",
        occupation: "Sales Executive",
        passport: user,
    },
    {
        id: 5,
        name: "Rojas Mbugi",
        occupation: "Sales Executive",
        passport: user,
    },
    {
        id: 6,
        name: "Rojas Mbugi",
        occupation: "Sales Executive",
        passport: user,
    },
];
