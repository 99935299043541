import React from 'react';
import { Grid } from '@mui/material';

const SpacingContent = ({ maxSpacing, children }) => {
    return (
        <>
            <Grid container justifyContent={'center'}>
                <Grid
                    item
                    sm={11}
                    xs={maxSpacing ? maxSpacing : 11.9}
                    sx={{ position: 'relative' }}
                >
                    {children}
                </Grid>
            </Grid>
        </>
    );
};

export default SpacingContent;
