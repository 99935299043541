import {
    Box,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { colors } from "../../hooks/useColor";
import CustomDialog from "../dialog/CustomDialog";
import Categories from "../Categories";

const CustomListItemTwo = ({ desktopOpen, menuToggle, text }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            {/* ############# Menu dialogy ################# */}
            <CustomDialog
                open={open}
                head={"Our products categories"}
                closeDialog={handleClose}
            >
                <Box
                    sx={{
                        p: 1,
                        bgcolor: colors.primary,
                        position: "relative",
                        borderBottomRightRadius: 10,
                        borderBottomLeftRadius: 10,
                        width: "100%",
                    }}
                >
                    <Categories
                        closeDialog={handleClose}
                        menuToggle={menuToggle}
                    />
                </Box>
            </CustomDialog>
            <NavLink onClick={handleOpen}>
                <ListItem disablePadding>
                    <ListItemButton
                        sx={{
                            height: 38,
                            justifyContent: desktopOpen ? "initial" : "center",
                            px: 2.5,
                            "&:hover": {
                                bgcolor: colors.primary,
                            },
                        }}
                    >
                        <ListItemText
                            disableTypography
                            primary={
                                <Typography
                                    sx={{ color: "#fff", fontSize: 20 }}
                                >
                                    {text}
                                </Typography>
                            }
                            sx={{
                                "& .MuiTypography-root": {
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    color: colors.textColor,
                                },
                            }}
                        />
                    </ListItemButton>
                </ListItem>
            </NavLink>
        </>
    );
};

export default CustomListItemTwo;
