import * as React from "react";
import Box from "@mui/material/Box";
import { navbarLinks1, navbarLinks2, productLink } from "../utils/navbarLinks";
import NavbarLink from "./NavbarLink";
import { Popper } from "@mui/material";
import Categories from "./Categories";
import { colors } from "../hooks/useColor";
import NavbarLinkTwo from "./NavbarLinkTwo";

export default function CustomNavbar() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleMenuToggle = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const id = open ? "simple-popper" : undefined;

    const links1 = navbarLinks1;
    const links2 = navbarLinks2;
    return (
        <React.Fragment>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                }}
            >
                {links1.map((link) => (
                    <NavbarLink link={link} key={link.id} />
                ))}
                <NavbarLinkTwo
                    link={productLink}
                    menuToggle={handleMenuToggle}
                    handleClose={handleClose}
                />
                {links2.map((link) => (
                    <NavbarLink link={link} key={link.id} />
                ))}
            </Box>
            <Box sx={{}}>
                <Popper
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    placement={"bottom-end"}
                    sx={{ zIndex: 1024 }}
                >
                    <Box
                        sx={{
                            p: 1,
                            bgcolor: colors.primary,
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: .5,
                            mr: 3.5,
                            position: "relative",
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: 10,
                            width: '100%'
                        }}
                    >
                        <Categories/>
                    </Box>
                </Popper>
            </Box>
        </React.Fragment>
    );
}
