import React from "react";
import PageBanner from "../../components/PageBanner";
import bgImage from "../../assets/media/images/gallary/g13.png";
import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../hooks/useColor";
import GallariesDisplay from "./components/GallariesDisplay";
import SpacingContent from "../../components/SpacingContent";
import { systemConfigSelector } from "../../states/features/selectors";
import { useSelector } from "react-redux";

const WhatWeDo = () => {
    // ############ Redux state ##############
    const { language } = useSelector(systemConfigSelector);

    return (
        <>
            <PageBanner bgImage={bgImage} height={{ md: "70vh", xs: "90vh" }}>
                <Grid container spacing={2} padding={2} sx={{ mt: 5 }}>
                    <Grid item sm={9} xs={12} sx={{ mt: { md: 0, xs: 20 } }}>
                        <Typography
                            className="mh-font"
                            sx={{
                                fontSize: { md: 70, xs: 40 },
                                fontWeight: "bold",
                                color: colors.white,
                                lineHeight: 1,
                            }}
                        >
                            {language === "swahili"
                                ? "Tuna mkusanyiko wa bidhaa zetu, huduma na matukio"
                                : "Expirience what takes place around us."}
                        </Typography>
                    </Grid>
                </Grid>
            </PageBanner>
            <Box sx={{ mt: -2}}>
                <SpacingContent>
                    <GallariesDisplay />
                </SpacingContent>
            </Box>
        </>
    );
};

export default WhatWeDo;
