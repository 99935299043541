import React from "react";
import { Box, Grid, IconButton } from "@mui/material";
import { colors } from "../hooks/useColor";
import SpacingContent from "./SpacingContent";
import CustomButton1 from "./MUIcustoms/CustomButton1";
import LanguageChangeBtn from "./MUIcustoms/LanguageChangeBtn";
import { Close, Segment } from "@mui/icons-material";

const TopBar = ({ handleDrawerToggle, mobileOpen }) => {
    return (
        <>
            <SpacingContent>
                <Box
                    sx={{
                        height: { md: 60, sm: 60, xs: "100%" },
                        display: "flex",
                        alignItems: "center",
                        zIndex: 1500,
                    }}
                >
                    <Grid container justifyContent={"flex-end"} spacing={2}>
                        <Grid item sm={3} xs={6}>
                            <Box sx={{ textAlign: "center" }}>
                                <a
                                    href={`tel:+255 658 331 633`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <CustomButton1
                                        variant={"contained"}
                                        sx={{ color: colors.white }}
                                        title="+255 658 331 633"
                                    />
                                </a>
                            </Box>
                        </Grid>
                        <Grid item sm={3} xs={6}>
                            <Box sx={{ textAlign: "center" }}>
                                <a
                                    href={`mailTo:info@zana.co.tz`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <CustomButton1
                                        variant={"contained"}
                                        sx={{
                                            color: colors.white,
                                            textTransform:
                                                "lowercase !important",
                                        }}
                                        title="info@zana.co.tz"
                                    />
                                </a>
                            </Box>
                        </Grid>
                        <Grid item sm={3} xs={6} pl={2}>
                            <Box
                                sx={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    zIndex: 1500,
                                }}
                            >
                                <LanguageChangeBtn />
                            </Box>
                        </Grid>
                        <Grid
                            item
                            sm={3}
                            xs={6}
                            px={2}
                            sx={{
                                display: {
                                    xs: "block",
                                    sm: "none",
                                    md: "none",
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                }}
                            >
                                {/* ############ Mob Toggle ############ */}
                                {mobileOpen ? (
                                    <IconButton
                                        size="small"
                                        onClick={handleDrawerToggle}
                                        color="inherit"
                                        sx={{
                                            display: {
                                                xs: "block",
                                                sm: "none",
                                            },
                                        }}
                                    >
                                        <Close
                                            sx={{ fontSize: 35, color: "#fff" }}
                                        />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        size="small"
                                        onClick={handleDrawerToggle}
                                        color="inherit"
                                        sx={{
                                            display: {
                                                xs: "block",
                                                sm: "none",
                                            },
                                            zIndex: 1500,
                                        }}
                                    >
                                        <Segment
                                            sx={{ fontSize: 35, color: "#fff" }}
                                        />
                                    </IconButton>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </SpacingContent>
        </>
    );
};

export default TopBar;
