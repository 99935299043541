import React from "react";
import Banner from "../components/Banner";
import RequestQuote from "../components/RequestQuote";
import OurPartiners from "../components/OurPartiners";
import OurProducts from "../components/OurProducts";

const Index = () => {
    return (
        <>
            {/* ################ Banner ################ */}
            <Banner />

            {/* ################ Our products ################ */}
            <OurProducts />

            {/* ################ RequestQuote ################ */}
            <RequestQuote />

            {/* ################ OurPartiners ################ */}
            <OurPartiners />
        </>
    );
};

export default Index;
