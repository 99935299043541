import { createSlice } from "@reduxjs/toolkit";
import { bannerSlideImages } from "../../dummyData/bannerSlideImages";

// => initialState
const initialState = {
    bannerImages: bannerSlideImages,
};
export const bannerSlice = createSlice({
    name: "bannerSlice",
    initialState,
    reducers: {
        setBannerImages: (state, action) => {
            state.bannerImages = action.payload;
        },
    },
});

export const { setBannerImages } = bannerSlice.actions;
export default bannerSlice.reducer;
