import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../hooks/useColor";
import SpacingContent from "../../components/SpacingContent";
import TeamWidget from "../../components/widgets/TeamWidget";
import vision from "../../assets/media/images/gallary/g5.png";
import mission from "../../assets/media/images/gallary/g4.png";
import values from "../../assets/media/images/gallary/g6.png";
import visionTitleImg from "../../assets/media/images/vision.png";
import missionTitleImg from "../../assets/media/images/mission.png";
// => Team image
import { useSelector } from "react-redux";
import {
    systemConfigSelector,
    teamSelector,
} from "../../states/features/selectors";
import MissionVisionValueWidget from "../../components/widgets/MissionVisionValueWidget";
import Section from "../../components/Section";

const AboutUs = () => {
    // ############ Redux state ##############
    const { language } = useSelector(systemConfigSelector);
    const { teamEng, teamSw } = useSelector(teamSelector);

    const visionMissionValues = [
        {
            id: 1,
            image: vision,
            title: language === "swahili" ? "Maono yetu" : "Our Vision",
            titleImg: visionTitleImg,
            description:
                language === "swahili"
                    ? "Zana Bora inakuwa muuzaji mashuhuri wa mashine na vifaa vya kilimo kwa wakati na ubora baada ya huduma ya kuuza"
                    : "Zana Bora becomes a specialized an agricultural machinery and equipments supplier with time and quality after sale service.",
        },
        {
            id: 2,
            image: mission,
            title: language === "swahili" ? "Dhamira Yetu" : "Our Mission",
            titleImg: missionTitleImg,
            border: true,
            description:
                language === "swahili"
                    ? "Kuwapatia wakulima wadogo, wa kati na wakubwa kuridhika kwa kiwango kikubwa na ubora, gharama nafuu na suluhisho la vifaa vya kilimo vinavyopatikana kwa urahisi."
                    : "To provide unmatched satisfaction to small, medium and large scale farmers with quality, cost effective and easily available agricultural machinery and equipment's solutions.",
        },
        {
            id: 3,
            image: values,
            title: language === "swahili" ? "Maadili yetu" : "Our Values",
            titleImg: visionTitleImg,
            description:
                language === "swahili"
                    ? "kuwapatia wakulima wadogo, wa kati na wakubwa kiwango cha juu cha kuridhika na ubora, gharama nafuu na ufumbuzi wa vifaa vya kilimo vinavyopatikana kwa urahisi."
                    : "We believe in strong and long term relationship with our esteemed customers but also cohesive working relationship within the team members and for that, the core values are derived from the company name.",
        },
    ];
    return (
        <>
            <Box>
                <Grid container justifyContent={"center"} spacing={10}>
                    <Grid item sm={10}>
                        <Typography
                            sx={{
                                fontSize: 45,
                                textAlign: "center",
                                py: 3,
                                color: colors.primary,
                                fontWeight: "bold",
                            }}
                        >
                            {language === "swahili"
                                ? "TIMU YA ZANA BORA"
                                : "Zana bora team"}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: { md: 20, sm: 20, xs: 16 },
                                textAlign: "center",
                                color: colors.primary,
                            }}
                        >
                            {language === "swahili"
                                ? "Zana Bora imeundwa kimkakati ili kutoa huduma bora na za haraka kwa wateja wetu waheshimiwa kote Tanzania. Kampuni yetu ina timu ndogo ya kufanya kazi kwa bidii na roho ya timu iliyoshikamana ili kufikia malengo yetu."
                                : "Zana Bora is strategically set up team in order to provide quality and quick services to our esteemed customers throughout Tanzania. Our company has a small hard working team with cohesive team spirit to achieve our targets."}
                        </Typography>
                    </Grid>
                    <Grid item sm={10}>
                        <Grid container justifyContent={"center"} spacing={5}>
                            {teamEng.map((member) => (
                                <Grid item sm={4} xs={6} key={member.id}>
                                    <TeamWidget team={member} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <SpacingContent>
                    <Section>
                        <Grid container spacing={2}>
                            {visionMissionValues.map((item) => (
                                <Grid item sm={4}>
                                    <MissionVisionValueWidget item={item} />
                                </Grid>
                            ))}
                        </Grid>
                    </Section>
                </SpacingContent>
            </Box>
        </>
    );
};

export default AboutUs;
